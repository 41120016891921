<template>
  <div class="">
    <a :href="shouldRedirect ? link : '#'" :title="tooltip" :class="`link--${icon}`">
       <div :class="icon && 'icon__wrapper'">
        <span
          v-if="showBadge && totalItems > 0"
          class="icon-badge pointer"
          >{{totalItems}}</span
        >
        <i
          :class="icon"
          :title="tooltip"
        ></i>
      </div>
    </a>
    <span v-if="showLabel">{{ tooltip }}</span>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ActionIconLink',
  props: {
    link: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      default: ''
    },
    disabledLink: {
      type:Boolean,
      default:false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    showBadge: {
      type: Boolean,
      default: false
    },
    shouldRedirect: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('cart', {
      totalItems: 'getTotalItems',
    }),
  }
};
</script>
