<template>
  <section
    v-if="getFinanceInfo && Object.keys(getFinanceInfo).length !== 0"
    class="financial_conditions_legal">
    <div
      v-if="isDesktopDeviceByHeaderAndResolution && !isTabletDevice"
      class="financial_conditions">
      <p>
        {{ $t('product.finance.financial_conditions') }}
        <span class="bold">{{ legalTextInfo }}</span>
        {{ $t('product.finance.financial_eci_teci') }}
      </p>
    </div>
    <div
      v-else
      class="financial_conditions">
      <p>
        {{ $t('product.finance.financial_conditions') }}
      </p>

      <p class="bold">
        {{ legalTextInfo }}
      </p>

      <p>
        {{ $t('product.finance.financial_eci_teci') }}
      </p>
    </div>
  </section>
</template>

<script>
  import useNavigatorDetect from 'CommonComposables/navigatorDetect';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'LegalInformationFinancing',
    setup() {
      const { t } = useI18n();
      const getFinanceInfo = mapGetter('pdp/getFinanceInfo');
      const { isTabletDevice, isDesktopDeviceByHeaderAndResolution } = useNavigatorDetect();
      
      const legalTextInfo = computed(() => { 
        const sameQuota = getFinanceInfo?.value?.lastQuotaAmount === getFinanceInfo?.value?.quotaType1?.quotaPrice ? true : false
        const keyFinance =
          sameQuota
            ? 'product.finance.financial_conditions_same_quota_teci'
            : 'product.finance.financial_conditions_different_quota_teci';

        return t(keyFinance, {
          totalQuotasNumber: sameQuota ? getFinanceInfo?.value?.quotaType1?.quotasAmount : Number(getFinanceInfo?.value?.quotaType1?.quotasAmount) - 1,
          quotaPrice: getFinanceInfo?.value?.quotaType1?.quotaPrice,
          lastQuotaAmount: getFinanceInfo?.value?.lastQuotaAmount,
          tae: getFinanceInfo?.value?.tae,
          tin: getFinanceInfo?.value?.tin2,
          totalCreditCost: getFinanceInfo?.value?.totalCreditCost,
          promotionManagementAmount: getFinanceInfo?.value?.managementCostAmount,
          totalPrice: getFinanceInfo?.value?.totalPrice,
          resultSimulationAmount: getFinanceInfo?.value?.resultSimulationAmount,
        });
      })

      return {
        getFinanceInfo,
        isTabletDevice,
        isDesktopDeviceByHeaderAndResolution,
        legalTextInfo,
      };
    },
  };
</script>

<style lang="less" scoped>
  .financial_conditions_legal {
    background-color: #fff;
    padding: 1rem;
    padding: 2rem 1rem;
    padding-bottom: 0px;
    .financial_conditions {
      font-size: 12px;
    }
  }
</style>
