import useNavigatorDetect from './navigatorDetect';
import UtilsCookies from 'CommonUtils/cookies';

export function AppEvents() {
  const { isIOS, isAndroid } = useNavigatorDetect();

  const sendMessage = (event, data) => {
    const m = {};
    m[event] = data || {};

    if (isAndroid() && typeof AppECI !== "undefined") {
      return AppECI.postMessage(JSON.stringify(m));
    }
    if (isIOS() && window.webkit) {
      return window.webkit.messageHandlers.AppECI.postMessage(m);
    }

    console.log('Mensaje enviado ' + event + JSON.stringify(m));
  };
  const openListenerCamera = (callback) => {
    window.geci.store.addEventListener('RES_PHOTO', callback);
  };
  const removeListenerCamera = () => {
    window.geci.store.removeEventListener('RES_PHOTO');
  };
  const closeModal = () => {
    sendMessage('closeModal');
  }
  const chatbotLoaded = () => {
    sendMessage('chatbotLoaded');
  };
  const messageUpdateCart = (data) => {
    sendMessage('postCartDone', data);
  };
  const messageNeedLogin = () => {
    sendMessage('needLogin');
  };
  const messageNativeShare = (message) => {
    sendMessage('socialNativeShare', message);
  };
  const messageContinueShopping = () => {
    sendMessage('continueShopping');
  };
  const messageEmptyCart = () => {
    sendMessage('postCartDone', { empty_basket: true, total_items: 0 });
  };
  const messageOpenCameraGallery = () => {
    sendMessage('REQ_PHOTO');
  };
  const messageCropEnd = (url) => {
    sendMessage('RES_CROP_END', url);
  };
  const messageRequestAppReview = () => {
    sendMessage('requestAppReview');
  };
  const messageSearchBarcode = () => {
    sendMessage('REQ_SUSTAINABILITY');
  };
  const messageSessionCartUpdate = () => {
    const data = {};

    data.commerce_id = UtilsCookies.getCookieValue('commerce_id') || undefined;
    data['x-xsrf'] = UtilsCookies.getCookieValue('x-xsrf') || undefined;
    data.zip = UtilsCookies.getCookieValue('zip') || undefined;
    data.dyn_user_id = UtilsCookies.getCookieValue('dyn_user_id') || undefined;
    data.dyn_user_confirm = UtilsCookies.getCookieValue('dyn_user_confirm') || undefined;
    if(UtilsCookies.getCookieValue('order_attempt_id')) data.order_attempt_id = UtilsCookies.getCookieValue('order_attempt_id') || undefined;
    data.firefly_cart = UtilsCookies.getCookieValue('firefly_cart') === 'true' ? true : false;
    sendMessage('sessionCartUpdate', data);
  };
  const messageExpertChat = (configChat) => {
    sendMessage('REQ_EXPERT_CHAT', configChat);
  }
  const messageSendUnits = (total_items) => {
    sendMessage('setCartUnits', { units: total_items || 0 });
  };
  const addListenerFromApp = (event, fn) => {
    if (!window.geci) window.geci = {};
    if (!window.geci.store) window.geci.store = {};
    createDispatcher(window.geci.store);

    window.geci.store.addEventListener(event, fn);
  }
  function createDispatcher (target) {
    target.listeners = {};
    target.createEvent = function(type,data) {
      var d = document, ev;
      if (!!d.createEvent) {
        ev = d.createEvent('CustomEvent');
        ev.initCustomEvent(type,true,true,data);
      } else if (d.createEventObject) {
        ev = d.createEventObject();
        ev.customType = type;
        ev.detail = data;
      }
      return ev;
    }

    target.dispatchEvent = function(ev, data, callback) {
      if (typeof(ev) === 'string') ev = this.createEvent(ev, data);
      else if (data) ev.detail = data;
      var list = this.listeners;
      if (list === undefined) return;
      var arr = list[ev.type ? ev.type : ev.customType];
      if (arr !== undefined) {
        if (ev.target === undefined) ev.target = this;
        for (var i=0; i < arr.length; i ++) {
          arr[i].call(this, ev, callback);
        }
      }
    }

    target.addEventListener = function (type, listener) {
      var l = this.listeners;
      if (l[type] === undefined) l[type] = [listener];
      else if (l[type].indexOf(listener) === -1) l[type].push(listener);
    }

    target.hasEventListener = function (type, listener) {
      var l = this.listeners;
      if (l[type] !== undefined && l[type].indexOf(listener) !== -1) return true;
      else return false;
    }

    target.removeEventListener = function (type, listener) {
      var l = this.listeners;
      var index = l[type].indexOf(listener);
      if (index !== - 1) l[type].splice(index, 1);
    }

    target.createEvent = function(type,data) {
      var d = document, ev;
      if (!!d.createEvent) {
        ev = d.createEvent('CustomEvent');
        ev.initCustomEvent(type,true,true,data);
      } else if (d.createEventObject) {
        ev = d.createEventObject();
        ev.customType = type;
        ev.detail = data;
      }
      return ev;
    }
  }
  const removeListenerFromApp = (event) => {
    if (window.geci.store.listeners[event])  delete window.geci.store.listeners[event];
  }

  return {
    closeModal,
    chatbotLoaded,
    messageUpdateCart,
    messageNeedLogin,
    messageNativeShare,
    messageContinueShopping,
    messageEmptyCart,
    messageOpenCameraGallery,
    messageCropEnd,
    messageRequestAppReview,
    messageSearchBarcode,
    messageSessionCartUpdate,
    messageSendUnits,
    openListenerCamera,
    removeListenerCamera,
    addListenerFromApp,
    removeListenerFromApp,
    messageExpertChat,
  };
}
