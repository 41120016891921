<template>
  <div
    v-if="placements?.footer?.html && isPdp && !isSearch"
    class="vp">    
    <Sheet
      :placement="placements.footer" />
  </div>
  <footer class="footer__wrapper">
    <LegalInformationFinancing v-if="isPdp && hasToGetFinancingDetail" />
    <Newsletter />
    <Logos />
    <Links />
    <Webs />
    <Copyright />
  </footer>
</template>
<script>
  import LegalInformationFinancing from './LegalInformationFinancing.vue';
  import Newsletter from './Newsletter.vue';
  import Logos from './Logos.vue';
  import Links from './Links.vue';
  import Webs from './Webs.vue';
  import Copyright from './Copyright.vue';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import Sheet from 'CommonComponents/Sheets/Render.vue';  

  export default {
    name: 'FooterComponent',
    components: {
      Sheet,
      LegalInformationFinancing,
      Newsletter,
      Logos,
      Links,
      Webs,
      Copyright
    },
    props: {
      isSearch: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const placements = mapGetter('page/getPlacements');
      const isPdp      = mapGetter('page/getIsPdp');
      const layoutOptions = mapGetter('page/getLayoutOptions');
      const hasToGetFinancingDetail = ref(layoutOptions.value.get_finance_detail_on_pdp)
      return {
        placements,
        isPdp,
        hasToGetFinancingDetail,
      }
    },
  };
</script>