<template>
  <div
    v-if="loyaltyData"
    class="loyalty">
    <a
      :href="getPointsUrl"
      :title="$t('loyalty-points')">
      <span class="loyalty-user_name">{{ `${userName}, ` }} </span>
      <span class="loyalty-points">{{ formatedPoints }}</span><span> {{ $t('loyalty-points') }}</span>
      <span
        v-if="loyaltyData.amount"
        class="loyalty-amount">{{ `${loyaltyData.amount}€` }}</span>
    </a>
  </div>
</template>

<script>
  import { mapGetter } from 'CommonUtils/store/state.js';
  import { computed } from 'vue';
 
  export default {
    name: 'LoyaltyPointsLabel',
    setup() {
      const loyaltyData = mapGetter('cart/getLoyaltyData');
      const userData = mapGetter('cart/getUser');
      const getSiteBar = mapGetter('page/getSiteBar');

      const userName = computed(() => {
        if (userData.value.alias) return userData.value.alias;

        return userData.value.first_name;
      });

      const formatedPoints = computed(() => {
        if (!loyaltyData.value) return '';
        return loyaltyData.value.points.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      })  

      const getPointsUrl = computed(() => {
        return getSiteBar.value.links?.points;
      })

      return { loyaltyData, userName, formatedPoints, getPointsUrl };
    },
  };
</script>
