<template>
  <transition name="transition-wishlist-added">
    <div v-show="show" class="wrapper__icon_wishlist-animated">
      <i class="i-wishlist-filled"></i>
      <div class="i-div-circle"></div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
};
</script>