<template>
  <div class="wrapper__variants">
    <p
      v-for="variant in item.variant"
      :key="variant.title"
      class="variant">
      {{ variant.title }}
      {{ normalizeValue(variant.value)?.replace(variant.title, '') }}
    </p>

    <p
      v-if="item.has_variants"
      class="variant">
      {{ item.has_variants }}
    </p>

    <CartBtnUnits
      :isDisabledPlusBtn="isDisabledPlusBtn"
      :hidePlusAndMinButtons="hidePlusAndMinButtons"
      :hideSelectors="hideSelectors"
      :item="item" />
    <div
      v-if="item.financial_info"
      class="finance_wrapper">
      <span class="finance_wrapper--text">{{ $t('product.finance.financed') }}</span>
    </div>
  </div>
</template>

<script>
  import CartBtnUnits from './CartBtnUnits.vue';

  export default {
    components: {
      CartBtnUnits
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      isDisabledPlusBtn: {
        type: Boolean,
        default: false,
      },
      isCheckout: {
        type: Boolean,
        default: false,
      },
      isBuyLater: {
        type: Boolean,
        default: false,
      },
      hideSelectors: {
        type: Boolean,
        default: false,
      },
      hidePlusAndMinButtons: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      normalizeValue(value) {
        if (typeof value === 'object') {
          return value[0] == value[1] ? value[0] : value.join(' ');
        }
        return value;
      },
    }
  };
</script>