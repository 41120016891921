/**
 * Enum for site types.
 * @readonly
 * @enum type of sites
 */
const SITE_TYPES = Object.freeze({
  GREEN_COAST: 'GREEN_COAST',
  LLOYDS: 'LLOYDS'
});

export default SITE_TYPES;
