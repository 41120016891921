<template>
  <div>
    <EciSalesforceChat
      v-if="ShowChatSupportFooterSalesforce"
      id="salesforce_chat"
      class="salesforce_chat"
      data-test-id="salesforce_chat"
      :script-src="chatDataSalesforce.script_src"
      :org-id="chatDataSalesforce.org_id"
      :esw-config-dev-name="chatDataSalesforce.esw_config_dev_name"
      :site-url="chatDataSalesforce.site_url"
      :scrt-url="chatDataSalesforce.scrt_url"
      :language="chatDataSalesforce.language"
      @available="availableHandler" />
  </div>
</template>

<script setup>
import EciSalesforceChat from './EciSalesforceChat.vue';
import { mapGetters, mapAction } from 'CommonUtils/store/state';

const {
  ShowChatSupportFooterSalesforce,
  chatDataSalesforce
} = mapGetters('support', {
  ShowChatSupportFooterSalesforce: 'getShowChatSupportFooterSalesforce',
  chatDataSalesforce: 'getChatDataSalesforce'
});

const toggleChatSalesforceOpened = mapAction('support', 'toggleChatSalesforceOpened');

function availableHandler(e) {
  // Invert the value of e if needed, depending on how your mutation works
  const newValue = !e;
  toggleChatSalesforceOpened(newValue);
}
</script>
