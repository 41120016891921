import { ref, computed, watch } from "vue";
import { mapGetters, mapActions } from "CommonUtils/store/state"

const useNewsletterForm = () => {
    const { user } = mapGetters('cart', { user: 'getUser' })
    const { email, privacyChecked, error, emailError, privacyError, blurred, privacyLink } = mapGetters('newsletter', {
      email: 'getEmail',
      error: 'getError',
      emailError: 'getEmailError',
      privacyError: 'getPrivacyError',
      privacyChecked: 'isPrivacyChecked',
      blurred: 'isBlurred',
      privacyLink: 'getPrivacyLink'
    })
    const { updateEmail, updatePrivacyChecked, updateBlurred } = mapActions('newsletter', [
      'updatePrivacyChecked',
      'updateEmail',
      'updateBlurred'
    ])
    
    const showEmailError = computed(() => blurred.value && !!emailError.value)
    const showPrivacyError = computed(() => blurred.value && !!privacyError.value)
    const formEmail = computed({
      get() { return email.value },
      set(value) { updateEmail(value) }
    })
    const formPrivacyChecked = computed({
      get() { return privacyChecked.value },
      set(value) { updatePrivacyChecked(value) }
    })
      
    const formEmailBlur = () => updateBlurred(true)

    // Autocomplete with user email if is logged and client dont touch email input
    const autoCompleteUserEmail = () => {
      if (blurred.value || !user.value?.email) return
      formEmail.value = user.value?.email
    }
    watch(user, autoCompleteUserEmail)

    // INIT
    formEmail.value = ''
    autoCompleteUserEmail()

    return {
      formEmail,
      formPrivacyChecked,
      formEmailBlur,
      showEmailError,
      showPrivacyError,
      emailError,
      privacyError,
      privacyLink
    }
}

export default useNewsletterForm