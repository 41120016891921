<template>
  <div :class="'nav-logo ' + logoClass">
    <a
      :aria-label="$t('layouts.header.corte_ingles')"
      :href="logoUrl"
      target="_self">
      <i :class="logo + ' _xl '"></i>
    </a>
    <a
      v-if="hasRibbon"
      class="ribbon"
      :style="ribbonStyle"
      :href="ribbonUrl"></a>
  </div>
</template>

<script>
import { mapGetter } from 'CommonUtils/store/state.js';
import { computed } from 'vue';
export default {
  name: 'Logo',
  props: {
    logoClass: {
      default: '_topbar',
    }
  },

  setup() {
    const logo = mapGetter('page/getLogo');
    const assetsBase = mapGetter('page/getAssetsBase');
    const customization = mapGetter('page/getLayoutOptions');
    const hasBrandCustomization = mapGetter('page/hasBrandCustomization');
    const brandCustomization = mapGetter('page/getBrandCustomization');

    const isCustomBrandEnabled = computed(() => {
      return hasBrandCustomization.value && brandCustomization.value.enabled;
    });

    const hasCustomBrandLogo = computed( ()=>  {
      return isCustomBrandEnabled.value && brandCustomization.value.customizations.logo === true;
    });

    const logoUrl = computed(() => {
      if (!hasCustomBrandLogo.value) return '/';

      const url = brandCustomization.value['slug'];
      if (!url) return '/';
      return '/' + url;
    });
    const hasRibbon = computed(() => {
      if(!customization || !customization.value || !customization.value.show_cancer_breast_support){
        return false;
      }
      return customization.value.show_cancer_breast_support;
    });

    const ribbonUrl = '/dia-mundial-contra-el-cancer-de-mama/?level=11';
    const ribbonStyle = { 'background-image': `url(${assetsBase.value}/stylesheets/images/cancer-mama.png)`};
    
    return {
      logo,
      logoUrl,
      hasRibbon,
      ribbonUrl,
      ribbonStyle
    };
  },
};
</script>

<style lang="less">
.nav-logo {
  a {
    &.ribbon {
      align-self: center;
      display: block;
      width: 30px;
      height: 30px;
      background-size: cover;
      &.show{
        display: block;
      }
    }
  }
}


</style>