<template>
  <section class="webs">
    <div class="webs__wrapper">
      <div class="webs__phones">
        <div
          v-if="ShowChatSupportFooterSalesforce || showChatSupportFooterECI || showChatSupportFooterFood"
          class="webs__phone">
          <p class="webs__phone-title webs__phone-item">
            {{ $t('layouts.footer.customer_support_chat')
            }}<span class="webs__phone-dots">:</span>
          </p>
          <span
            v-if="ShowChatSupportFooterSalesforce"
            role="button"
            tabindex="0"
            aria-pressed="false"
            aria-describedby="chatSupportFooterEciScheduleTooltip"
            class="webs__phone-link webs__phone-link--left webs__phone-item"
            :class="{ disabled: chatSalesforceOpened }"
            @click.prevent.stop="
              !chatSalesforceOpened &&
                openChatSalesforce()
            "
            @keyup.stop.enter.space="
              !chatSalesforceOpened &&
                openChatSalesforce()
            ">
            <Web.ChatSupportIcon /><span class="webs__phone-name">{{ $t('layouts.footer.orders_phone_section') }}...</span>
            <span
              id="chatSupportFooterEciScheduleTooltip"
              class="tooltip"
              role="tooltip">{{ chatSupportFooterEciSchedule?.schedule }}</span>
          </span>
          <span
            v-if="showChatSupportFooterECI && !ShowChatSupportFooterSalesforce"
            role="button"
            tabindex="0"
            aria-pressed="false"
            aria-describedby="chatSupportFooterEciScheduleTooltip"
            class="webs__phone-link webs__phone-link--left webs__phone-item"
            :class="{ disabled: !chatSupportFooterEciSchedule?.available }"
            @click.prevent.stop="
              chatSupportFooterEciSchedule?.available &&
                openChat(chatFooterConfig, CHAT_TYPES.SUPPORT)
            "
            @keyup.stop.enter.space="
              chatSupportFooterEciSchedule?.available &&
                openChat(chatFooterConfig, CHAT_TYPES.SUPPORT)
            ">
            <Web.ChatSupportIcon /><span class="webs__phone-name">{{ $t('layouts.footer.orders_phone_section') }}...</span>
            <span
              id="chatSupportFooterEciScheduleTooltip"
              class="tooltip"
              role="tooltip">{{ chatSupportFooterEciSchedule?.schedule }}</span>
          </span>
          <span
            v-if="showChatSupportFooterFood && !ShowChatSupportFooterSalesforce"
            role="button"
            tabindex="0"
            aria-pressed="false"
            aria-describedby="chatSupportFooterFoodScheduleTooltip"
            class="webs__phone-link webs__phone-link--left webs__phone-item"
            :class="{ disabled: !chatSupportFooterFoodSchedule?.available }"
            @click.prevent.stop="
              chatSupportFooterFoodSchedule?.available &&
                openChat(chatFoodConfig, CHAT_TYPES.SUPPORT)
            "
            @keyup.stop.enter.space="
              chatSupportFooterFoodSchedule?.available &&
                openChat(chatFoodConfig, CHAT_TYPES.SUPPORT)
            ">
            <Web.ChatSupportIcon /><span class="webs__phone-name">{{
              $t('layouts.footer.supermercado')
            }}</span>
            <span
              id="chatSupportFooterFoodScheduleTooltip"
              class="tooltip"
              role="tooltip">{{ chatSupportFooterFoodSchedule?.schedule }}</span>
          </span>
        </div>

        <div class="webs__phone">
          <p class="webs__phone-title webs__phone-item">
            {{ $t('layouts.footer.orders_phone')
            }}<span class="webs__phone-dots">:</span>
          </p>
          <a
            class="webs__phone-link webs__phone-item"
            :href="telHrefFormat(links?.phones?.orders_phone?.orders_phone)"><span class="webs__phone-name">{{ $t('layouts.footer.orders_phone_section') }}...
              <strong class="webs__phone-number">{{
                telFormat(links?.phones?.orders_phone?.orders_phone)
              }}</strong></span><i class="webs__phone-icon icon phone-line"></i></a>
          <a
            class="webs__phone-link webs__phone-item"
            :href="telHrefFormat(links?.phones?.orders_phone?.supermercado)"><span class="webs__phone-name">{{ $t('layouts.footer.supermercado') }}:
              <strong class="webs__phone-number">{{
                telFormat(links?.phones?.orders_phone?.supermercado)
              }}</strong></span><i class="webs__phone-icon icon phone-line"></i></a>
        </div>

        <div class="webs__phone">
          <p class="webs__phone-title webs__phone-item">
            {{ $t('layouts.footer.customer_support')
            }}<span class="webs__phone-dots">:</span>
          </p>
          <a
            class="webs__phone-link webs__phone-item"
            :href="telHrefFormat(links?.phones?.customer_support?.orders_phone)"><span class="webs__phone-name">{{ $t('layouts.footer.orders_phone_section') }}...
              <strong class="webs__phone-number">{{
                telFormat(links?.phones?.customer_support?.orders_phone)
              }}</strong></span><i class="webs__phone-icon icon phone-line"></i></a>
          <a
            class="webs__phone-link webs__phone-item"
            :href="telHrefFormat(links?.phones?.customer_support?.supermercado)"><span class="webs__phone-name">{{ $t('layouts.footer.supermercado') }}:
              <strong class="webs__phone-number">{{
                telFormat(links?.phones?.customer_support?.supermercado)
              }}</strong></span><i class="webs__phone-icon icon phone-line"></i></a>
        </div>
      </div>

      <div
        class="webs__links"
        :class="{ 'webs__links--with-chat': isLogged }">
        <div
          id="footerOtrasWebs"
          class="webs__link webs__link--big"
          role="button"
          :aria-expanded="checkedBlocks.otrasWebs"
          :aria-control="$t('layouts.footer.otras_webs')"
          tabindex="0"
          @click.stop="checkedBlocks.otrasWebs = !checkedBlocks.otrasWebs"
          @keydown.prevent.enter.space="
            checkedBlocks.otrasWebs = !checkedBlocks.otrasWebs
          ">
          <div
            class="webs__link-label"
            for="chkWebsECI"
            :aria-label="$t('layouts.footer.otras_webs')">
            <span class="webs__link-title">{{
              $t('layouts.footer.otras_webs')
            }}</span>
            <i
              class="webs__link-icon icon arrow-down"
              :class="{ 'webs__link-icon--open': checkedBlocks.otrasWebs }"></i>
          </div>
          <AccordionAnimation>
            <div
              v-show="checkedBlocks.otrasWebs"
              class="webs__link-list-wrapper"
              aria-labelledby="footerOtrasWebs">
              <ul class="webs__link-list">
                <!-- TODO: @Juan García Espinal - Pendiente Analytics -->
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.supermercado">{{ $t('layouts.footer.supermercado') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.hipercor">{{
                      $t('layouts.footer.hipercor')
                    }}</a>
                </li>
                <!-- Pendiente Analytics -->
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.primeriti">{{
                      $t('layouts.footer.primeriti')
                    }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.venta_entradas">{{ $t('layouts.footer.venta_entradas') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.sfera">{{
                      $t('layouts.footer.sfera')
                    }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.supercor">{{
                      $t('layouts.footer.supercor')
                    }}</a>
                </li>
              </ul>
              <ul class="webs__link-list">
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.bodamas">{{
                      $t('layouts.footer.bodamas')
                    }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.viajes_corte">{{ $t('layouts.footer.viajes_corte') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.bricor">{{
                      $t('layouts.footer.bricor')
                    }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.sportown">{{
                      $t('layouts.footer.sportown')
                    }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.luxe">{{
                      $t('layouts.footer.luxe')
                    }}</a>
                </li>
              </ul>
              <ul class="webs__link-list">
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.corte_ingles">{{ $t('layouts.footer.corte_ingles') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.financiera_corte">{{ $t('layouts.footer.financiera_corte') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.corte_ingles_portugal">{{ $t('layouts.footer.corte_ingles_portugal') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.corte_sicor_alarmas">{{ $t('layouts.footer.corte_sicor_alarmas') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.corte_sicor_asiste">{{ $t('layouts.footer.corte_sicor_asiste') }}</a>  
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :href="links?.links?.energia_corte_ingles">{{ $t('layouts.footer.energia_corte_ingles') }}</a>  
                </li>
              </ul>
            </div>
          </AccordionAnimation>
        </div>

        <div class="webs__link">
          <div
            id="footerOtrosLinks"
            class="webs__link-label"
            role="button"
            :aria-expanded="checkedBlocks.otrosLinks"
            :aria-control="$t('layouts.footer.otros_links')"
            tabindex="0"
            @click.stop="checkedBlocks.otrosLinks = !checkedBlocks.otrosLinks"
            @keydown.prevent.enter.space="
              checkedBlocks.otrosLinks = !checkedBlocks.otrosLinks
            ">
            <span class="webs__link-title">{{
              $t('layouts.footer.otros_links')
            }}</span>
            <i
              class="webs__link-icon icon arrow-down"
              :class="{ 'webs__link-icon--open': checkedBlocks.otrosLinks }"></i>
          </div>
          <AccordionAnimation>
            <div
              v-show="checkedBlocks.otrosLinks"
              class="webs__link-list-wrapper"
              aria-labelledby="footerOtrosLinks">
              <ul class="webs__link-list">
                <li>
                  <a
                    class="webs__link-link"
                    :data-text="$t('modal.message.external_link')"
                    :href="links?.links?.optica_2000">{{ $t('layouts.footer.optica_2000') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :data-text="$t('modal.message.external_link')"
                    href="https://www.motortown.es/">{{ $t('layouts.footer.motortown') }}</a>
                </li>
                <li>
                  <a
                    class="webs__link-link"
                    :data-text="$t('modal.message.external_link')"
                    href="https://seguros.elcorteingles.es/?utm_source=elcorteingles&utm_medium=referral&utm_campaign=footer">{{ $t('layouts.footer.corte_ingles_seguros') }}</a>
                </li>
              </ul>
            </div>
          </AccordionAnimation>
        </div>
      </div>

      <div class="webs__social">
        <a
          class="webs__social-link"
          :href="links?.social?.apple_store"
          :alt="$t('layouts.footer.apple_store')"
          :title="$t('layouts.footer.apple_store')"><span class="webs__social-logo"><i class="webs__social-icon icon apple"></i><strong class="webs__social-text">{{
            $t('layouts.footer.webs.apple_store')
          }}</strong></span></a>
        <a
          class="webs__social-link"
          :href="links?.social?.google_play"
          :alt="$t('layouts.footer.google_play')"
          :title="$t('layouts.footer.google_play')"><span class="webs__social-logo"><i class="webs__social-icon icon google-play"></i><strong class="webs__social-text">{{
            $t('layouts.footer.webs.google_play')
          }}</strong></span></a>
        <a
          class="webs__social-link"
          :href="links?.social?.confianza"
          :aria-label="$t('layouts.footer.confianza')"><i
            class="webs__social-icon webs__social-icon--confianza icon-confianza"></i></a>
      </div>
      <SalesforceChat />
    </div>
  </section>
</template>

<script>
export default { name: 'Webs' };
</script>
<script setup>
import { ref, onMounted, inject } from 'vue';
import { mapGetters } from 'CommonUtils/store/state';
import AccordionAnimation from 'CommonComponents/animations/AccordionAnimation.vue';
import * as Web from './Webs/webs';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
import { CHAT_TYPES } from 'CommonUtils/chatUtils';
import SalesforceChat from './Chat/SalesforceChat.vue';

const { links } = mapGetters('page', { links: 'getFooterWebs' });

const {
  chatConfig,
  showChatSupportFooterECI,
  showChatSupportFooterFood,
  chatSupportFooterEciSchedule,
  chatSupportFooterFoodSchedule,
  ShowChatSupportFooterSalesforce,
  chatDataSalesforce,
  chatSalesforceOpened
} = mapGetters('support', {
  chatConfig: 'getChatConfig',
  ShowChatSupportFooterSalesforce: 'getShowChatSupportFooterSalesforce',
  showChatSupportFooterECI: 'getShowChatSupportFooterECI',
  showChatSupportFooterFood: 'getShowChatSupportFooterFood',
  chatSupportFooterEciSchedule: 'getChatSupportFooterEciSchedule',
  chatSupportFooterFoodSchedule: 'getChatSupportFooterFoodSchedule',
  chatDataSalesforce:'getChatDataSalesforce',
  chatSalesforceOpened: 'getChatSalesforceOpened'
});
const { isLogged, user } = mapGetters('cart', { 
  isLogged: 'isLogged',
  user: 'getUser'
});
const telFormat = tel =>
  tel && tel.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
const telHrefFormat = tel => `tel:${tel}`;

const checkedBlocks = ref({});
// TODO: Mostrar en base usuario logueado y datos de _layout_options>chatsupport y chat-support...>available/schedule

const openChat = inject('openChat');



const chatFooterConfig = chatConfig.value.all_configs?.find(settings => {
  return settings.name === 'chat-footer-eci';
});
const chatFoodConfig = chatConfig.value.all_configs?.find(settings => {
  return settings.name === 'chat-support-footer-food';
});

function openChatSalesforce() {
  const salesforce_chat_element = document.getElementById('salesforce_chat');
    let event
    if (user.value.id){
      event = new CustomEvent('open-chat', {
        detail: {'Pairwise' : user.value.id, 'URL_origen' : window.location.href, 'Source': chatDataSalesforce.value.source, 'Language': chatDataSalesforce.value.language },
      });
    } else {
      event = new CustomEvent('open-chat', {
        detail: {'URL_origen' : window.location.href, 'Source': chatDataSalesforce.value.source, 'Language': chatDataSalesforce.value.language },
      });
    }
    salesforce_chat_element.dispatchEvent(event);
}

onMounted(() => {
  const { isDesktopDevice } = useNavigatorDetect();
  if (!isDesktopDevice.value) return;
  checkedBlocks.value.otrasWebs = true;
  checkedBlocks.value.otrosLinks = true;
});
</script>
