<template>
  <div>
    <div class="cart-list__item">
      <div class="basket__wrapper">
        <CartImage :item="item" />

        <div class="description__wrapper">
          <Brand
            v-if="isValidBrand"
            :brand="item.brand"
            :className="'cart'" />

          <a
            class="title"
            :href="PdpRedirect.goToPDP(item)">
            {{ item.title }}
          </a>

          <CartVariants
            :isDisabledPlusBtn="!canAddUnits"
            :hidePlusAndMinButtons="hideSelectors"
            :hideSelectors="Boolean(item.custom_items)"
            :item="item" />

          <Price
            v-if="!item.must_hide_price"
            :item="item"
            class="price" />

          <CartActionsMiniBasket
            v-if="showDeleteItem"
            :item="item" />

          <CartItemRelatedServices 
            v-if="itemHasInstallationServices || itemHasInsuranceServices"
            :item="item" />
        </div>
      </div>
      <div
        v-if="item.description"
        class="textError cart-list__item__description">
        <p>* {{ $t(item.description) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue'
  import PdpRedirect from 'CommonUtils/pdpRedirect.js';
  import ITEM_TYPES from 'CommonUtils/itemTypes.js';
  import useCartActionsLogic from 'CommonComposables/useCartActionsLogic';

  import CartImage from './UI/CartImage.vue';
  import Price from 'CommonComponents/Price/Price.vue';
  import CartVariants from './UI/CartVariants.vue';
  import Brand from 'CommonComponents/Product/Brand.vue';
  import CartItemRelatedServices from 'CommonComponents/Cart/CartItemRelatedServices.vue';
  import CartActionsMiniBasket from 'Components/Checkout/CartActionsMiniBasket.vue';

  const { hasInstallationServices, hasInstallationInsuranceServices } = useCartActionsLogic();

  const props = defineProps({
    hideSelectors: { type: Boolean, default: false },
    item: { type: Object, required: true },
    canAddUnits: { type: Boolean, default: true },
  });

  const showDeleteItem = computed(() => Boolean(props.item.financial_info || props.item.custom_items || props.hideSelectors));

  const isValidBrand = computed(() => props.item.brand && props.item.brand.name && props.item.type !== ITEM_TYPES.ECI_PRIME);

  const itemHasInstallationServices = computed(() => hasInstallationServices(props.item));
  const itemHasInsuranceServices = computed(()=>hasInstallationInsuranceServices(props.item));

</script>

<style lang="less" scoped>
  @import 'LessFiles/fontsSizes.less';

  .cart-list__item {
    padding-bottom: 1.5em;

    .basket__wrapper .image__wrapper {
      width: 80px;
      height: 100px;
      flex-shrink: 0;
    } 

    .description__wrapper {
      width: auto;
      flex-grow: 1;
      overflow: hidden;

      .title {
        font-size: @font-size-xs;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0.75em;
      }

      .price {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 0;
      } 
    }
  }
</style>
