<template>
  <svg version="1.1" id="briefing" stroke-width="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 25" style="enable-background:new 0 0 30 25;" xml:space="preserve">
    <path id="sm-briefing" d="M1.2,19.4c0,0.3,0.3,0.6,0.6,0.6h10.8c0.2,0,0.4,0,0.5,0.2l2.4,2.8v-2.2c0-0.4,0.3-0.7,0.7-0.8h2.4 
      c0.5,0,0.6-0.2,0.6-0.6v-3.1h1.2v3.2c0,1-0.8,1.8-1.8,1.8c0,0,0,0,0,0h-1.9v3.1c0,0.2-0.3,0.5-0.5,0.6C16.2,25,16.1,25,15.9,25 
      c-0.2,0-0.3-0.1-0.4-0.2l-3.2-3.6H1.8c-1,0-1.8-0.7-1.8-1.7c0,0,0,0,0,0V8c0-1,0.8-1.8,1.8-1.8c0,0,0,0,0,0h6.9v1.2H1.9 
      c-0.3,0-0.6,0.2-0.6,0.6V19.4z M4.4,12.5h2.5V15H4.4V12.5z M28.2,0H11.8c-1,0-1.7,0.8-1.8,1.7c0,0,0,0,0,0v11.5c0,1,0.8,1.7,1.7,1.8 
      c0,0,0,0,0,0h2v3.1c0,0.3,0.2,0.6,0.5,0.6c0.3,0.1,0.6,0,0.8-0.2l3.1-3.6h10.2c1,0,1.7-0.8,1.8-1.7c0,0,0,0,0,0V1.8 
      C30,0.8,29.2,0,28.2,0z M28.8,13.2c0,0.3-0.2,0.6-0.6,0.6c0,0,0,0,0,0H17.6c-0.2,0-0.3,0.1-0.4,0.2L15,16.8v-2.3 
      c0-0.3-0.3-0.6-0.6-0.7h-2.6c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0,0V1.8c0-0.3,0.2-0.6,0.6-0.6c0,0,0,0,0,0h16.3c0.3,0,0.6,0.2,0.6,0.5 
      c0,0,0,0,0,0V13.2z M17.5,7.5c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C16.9,6.3,17.5,6.8,17.5,7.5z M21.2,7.5 
      c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C20.7,6.3,21.2,6.8,21.2,7.5z M25,7.5c0,0.7-0.6,1.2-1.2,1.2 
      s-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C24.4,6.3,25,6.8,25,7.5z"/> 
  </svg>
</template>

<script> export default { name: 'ChatSupportIcon' }; </script>