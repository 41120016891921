<template>
  <svg version="1.2" baseProfile="tiny" id="click_and_express" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 172.5 16.2" xml:space="preserve" style="min-height: 17px; margin-bottom: 1px;">
    <g>
      <polygon fill="#008C2E" points="150.5,14 168.2,9 150.5,4 	"/>
    </g>
    <g>
      <path fill="#242424" d="M8.9,4v1.7H3.5v2.5h4.9v1.7H3.5v2.5h5.6v1.7H1.6V4H8.9z"/>
    </g>
    <g>
      <path fill="#242424" d="M12.9,4l5.2,6.9V4H20v10h-1.8L13,7.2V14h-1.9V4H12.9z"/>
    </g>
    <g>
      <path fill="#242424" d="M29.4,4v1.7h-3.1V14h-1.9V5.7h-3.1V4H29.4z"/>
    </g>
    <g>
      <path fill="#242424" d="M37,14.1l-1.7-3c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0h-2.3V14h-1.9V4H35c1.3,0,2.3,0.3,3,0.9s1,1.5,1,2.6
        c0,0.8-0.2,1.5-0.5,2s-0.8,1-1.5,1.2l2.2,3.3H37V14.1z M32.7,9.4H35c0.7,0,1.3-0.2,1.7-0.5s0.6-0.8,0.6-1.4s-0.2-1.1-0.6-1.4
        c-0.4-0.3-0.9-0.5-1.7-0.5h-2.3V9.4z"/>
    </g>
    <g>
      <path fill="#242424" d="M48.5,4v1.7H43v2.5h4.9v1.7H43v2.5h5.6v1.7h-7.5V4H48.5z"/>
    </g>
    <g>
      <path fill="#242424" d="M57.4,9.1h1.7v3.8c-0.3,0.2-0.5,0.4-0.9,0.5c-0.3,0.2-0.6,0.3-1,0.4c-0.3,0.1-0.7,0.2-1.1,0.3
        c-0.4,0.1-0.7,0.1-1.1,0.1c-0.7,0-1.4-0.1-2.1-0.4c-0.6-0.3-1.2-0.6-1.7-1.1s-0.8-1-1.1-1.6s-0.4-1.3-0.4-2s0.1-1.4,0.4-2
        c0.3-0.6,0.7-1.2,1.1-1.6s1.1-0.8,1.7-1.1C53.7,4.1,54.4,4,55.2,4c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.3,0.6,1.8,1L58,6.8
        c-0.4-0.4-0.8-0.6-1.3-0.8c-0.5-0.2-1-0.3-1.5-0.3S54.3,5.8,53.8,6S53,6.4,52.7,6.7s-0.5,0.6-0.7,1c-0.2,0.4-0.3,0.8-0.3,1.3
        s0.1,0.9,0.3,1.3s0.4,0.8,0.7,1.1s0.7,0.5,1.1,0.7c0.4,0.2,0.9,0.3,1.4,0.3c0.3,0,0.7-0.1,1.1-0.2s0.7-0.3,1.1-0.5
        C57.4,11.7,57.4,9.1,57.4,9.1z"/>
    </g>
    <g>
      <path fill="#242424" d="M67.8,11.9h-4.9L62,14.1h-2l4.4-10h2l4.3,10h-2.1L67.8,11.9z M67.1,10.3l-1.7-4.2l-1.8,4.2H67.1z"/>
    </g>
    <g>
      <path fill="#242424" d="M83.6,4v1.7h-5.4v2.5H83v1.7h-4.9v2.5h5.6v1.7h-7.5V4H83.6z"/>
    </g>
    <g>
      <path fill="#242424" d="M87.5,4l5.2,6.9V4h1.9v10h-1.8l-5.2-6.8V14h-1.9V4H87.5z"/>
    </g>
    <g>
      <path fill="#242424" d="M108.7,4v1.7h-5.4v2.5h4.9v1.7h-4.9v2.5h5.6v1.7h-7.5V4H108.7z"/>
    </g>
    <g>
      <path fill="#242424" d="M112.7,4v8.3h4.3V14h-6.2V4H112.7z"/>
    </g>
    <g>
      <path fill="#242424" d="M127,4c0.8,0,1.4,0.1,2.1,0.4c0.6,0.2,1.2,0.6,1.7,1s0.8,1,1.1,1.6s0.4,1.3,0.4,2s-0.1,1.4-0.4,2
        c-0.3,0.6-0.6,1.1-1.1,1.6c-0.5,0.4-1,0.8-1.7,1c-0.6,0.2-1.4,0.4-2.1,0.4h-4.1V4H127z M127.1,12.4c0.5,0,0.9-0.1,1.3-0.2
        c0.4-0.2,0.7-0.4,1-0.7s0.5-0.6,0.7-1s0.2-0.9,0.2-1.3s-0.1-0.9-0.3-1.4c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.5-1-0.7
        c-0.4-0.2-0.8-0.2-1.3-0.2h-2.2v6.7h2.3V12.4z"/>
    </g>
    <g>
      <path fill="#242424" d="M137.2,1.6L135.4,3h-1.6l1.5-2.2L137.2,1.6z M135.9,4v10H134V4H135.9z"/>
    </g>
    <g>
      <path fill="#242424" d="M144.8,11.9h-4.9L139,14h-2l4.4-10h2l4.3,10h-2.1L144.8,11.9z M144.2,10.3l-1.7-4.2l-1.8,4.2H144.2z"/>
    </g>
  </svg>
</template>

<script> export default { name: 'ClickAndExpress' }; </script>