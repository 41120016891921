<template>
  <div class="details__wrapper">
    <div
      v-for="(discount, index) in fullDiscountsData"
      :key="index"
      class="row_details_discounts_wrapper">
      <RowDetails
        :label="`${discount.discount_description[0]}`"
        :info="`${discount.discount_price}`" />
    </div>
    <RowDetails
      label="Total"
      :info="total_price"
      :isTotalPrice="true" />

    <button
      class="btn-apply-cart pointer"
      @click="closeModal">
      {{ isCSC ? $t('cart.return_csc') : $t('cart.checkout') }}
    </button>
  </div>
</template>

<script setup>
  import { shallowRef, watchEffect } from 'vue';
  import { mapGetter, mapAction } from 'CommonUtils/store/state';
  import RowDetails from './RowDetails.vue';

  const props = defineProps({
    summary: { type: Object, required: true },
    fullDiscountsData: { type: Object, required: true },
  });

  const total_price = shallowRef('');
  const total_shipping_price = shallowRef('');
  const isCSC = mapGetter('cart/getIsCSC');

  function setPrices(summary) {
    total_shipping_price.value = summary.total_shipping_price;
    total_price.value = summary.total_price;
  }
  watchEffect(() => setPrices(props.summary));

  const redirectUrlBasket = mapGetter('cart/getRedirectUrlBasket');
  const hideAllModals = mapAction('uiState', 'hideAllModals');
  function closeModal() {
    document.body.style.overflowY = 'auto';
    hideAllModals();
    window.location.href = redirectUrlBasket.value;
  }
</script>
