<template>
  <button
    v-if="show"
    id="accesibility"
    class="btn accesibility"
    :class="{
      'accesibility-pdp': isPDP && !isNewPDP,
      'accesibility-new_pdp': isNewPDP,
    }"
    aria-label="accesibility"
    @click="toggle">
    <svg
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 11.299 14.886"
      xml:space="preserve">
      <path
        fill="#FFFFFF"
        d="M11.271,3.656c-0.117-0.442-0.619-0.708-1.061-0.59c0,0-3.361,1.09-4.57,1.09
      c-1.178,0-4.509-1.061-4.509-1.061C0.689,2.978,0.188,3.184,0.041,3.626c-0.147,0.471,0.118,0.972,0.59,1.09l3.625,1.09
      c0.177,0.06,0.325,0.236,0.296,0.442c-0.03,0.59-0.089,1.858-0.207,2.329c-0.295,1.356-1.916,5.07-1.916,5.07
      c-0.207,0.442,0,0.943,0.413,1.15c0.118,0.059,0.236,0.088,0.354,0.088c0.324,0,0.648-0.178,0.766-0.501L5.7,10.848l1.651,3.567
      c0.147,0.293,0.441,0.471,0.767,0.471c0.118,0,0.235-0.029,0.354-0.088c0.44-0.207,0.617-0.708,0.412-1.15
      c0,0-1.621-3.714-1.917-5.07C6.849,8.106,6.791,6.839,6.761,6.249c0-0.206,0.118-0.382,0.295-0.442l3.625-1.09
      C11.124,4.599,11.388,4.097,11.271,3.656z M5.7,3.302c0.912,0,1.651-0.739,1.651-1.65S6.612,0,5.7,0C4.788,0,4.049,0.74,4.049,1.651
      S4.788,3.302,5.7,3.302z" />
    </svg>
  </button>
</template>

<script>
import { onMounted } from 'vue';
import UtilsCookies from 'CommonUtils/cookies.js';
import { mapGetter } from 'CommonUtils/store/state.js';

export default {
  name: 'EqualWebAccesibility',
  setup() {
    const show = ref(false);
    const isPDP = mapGetter('page/getIsPdp');
    const isApp = mapGetter('page/isApp');
    const isNewPDP = mapGetter('page/getIsNewPDP');
    const hasOpenVariantSelector = mapGetter('page/hasOpenVariantSelector');

    onMounted(() => {
      const shouldHideAccesibilityButton = UtilsCookies.getCookieValue('INDhideAccBtn');
      const hasSDKLoaded = true;
      show.value = !(shouldHideAccesibilityButton || !hasSDKLoaded || isApp.value);
    });

    const toggle = () => {
      window.interdeal.a11y.openMenu();
    };
    const getBottomDistance = () => {
      if (hasOpenVariantSelector.value) {
        return '14rem';
      }
      return '9rem';
    };

    return {
      show,
      toggle,
      isPDP,
      isNewPDP,
      getBottomDistance
    };
  },
};
</script>

<style lang="less">
@import 'CommonLessFiles/variables/screen.less';
.accesibility {
  background-color: #242424;
  outline: inherit;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
  position: fixed;
  z-index: 6;
  transition: bottom 0.5s;
  left: 1.75rem;
  bottom: 2rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
  .scrolled & {
    bottom: 6rem;
  }
  @media @l-up {
    left: 1.75rem;
    bottom: 1.75rem;
  }
  &-pdp {
    bottom: 14rem;
    @media @l-up {
      bottom: 1.75rem;
    }
  }
  &-new_pdp {
    @media @desktop {      
      bottom: 1.75rem;
    }
    @media @mobile {
      left: unset;
      right: 1rem;
      bottom: v-bind('getBottomDistance()');
    }
  }
}
#INDmenu-btn {
  display: none !important;
}
#INDblindNotif {
  display: none !important;
}
</style>