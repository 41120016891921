<template>
  <div>
    <div style="margin: 1rem 0">
      <div class="gift__wrapper">
        <div class="gift-info">
          <CartImage :item="gift" />

          <div class="description__wrapper">
            <Brand
              v-if="isGiftBrand"
              :brand="gift.brand"
              class="cart" />

            <span class="title">{{ gift.title }}</span>
          </div>
        </div>
        <div class="gift-badge">
          <i class="gift_icon"></i>
          {{ $t('global.free') }}
        </div>
      </div>
      <div
        v-if="gift.description"
        class="textError">
        <p>* {{ $t(gift.description) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import ITEM_TYPES from 'CommonUtils/itemTypes.js';
  import CartImage from './UI/CartImage.vue';
  import Brand from 'CommonComponents/Product/Brand.vue';

  const props = defineProps({
    gift: { type: Object, required: true },
  });
  const isGiftBrand = computed(
    () => props.gift.brand && props.gift.brand.name && props.gift.type !== ITEM_TYPES.ECI_PRIME,
  );
</script>
