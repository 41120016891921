<template>
  <search
    class="search-bar"
    role="search">
    <form
      class="search-bar__form"
      :aria-label="$t('search.form')"
      @submit.prevent="handleSearch">
      <ButtonUI
        class="search-bar__button search-bar__button--text"
        type="TERTIARY"
        data-store-id=""
        data-synth="LOCATOR_SEARCH_BUTTON"
        :aria-label="$t('global.search')">
        <MagnifyingGlassIcon class="search-bar__icon" />
      </ButtonUI>
      <input
        ref="searchInput"
        v-model="searchTerm"
        class="search-bar__input"
        type="search"
        enterkeyhint="search"
        autocomplete="off"
        data-synth="LOCATOR_SEARCH_INPUT"
        :placeholder="searchPlaceHolder"
        @focus="onFocus"
        @blur="onBlur"
        @keyup.esc="clearSearch" />
      <ButtonUI
        v-if="shouldShowTypeahead || searchTerm"
        class="search-bar__button search-bar__button--clear"
        type="TERTIARY"
        :aria-label="$t('search.clear')"
        @click.prevent="clearSearch">
        <CloseIcon class="search-bar__icon" />
      </ButtonUI>
      <span class="search-bar__input-border">
        <i></i>
      </span>
      <ButtonUI
        v-if="shouldShowSearchVoice && !shouldShowSearchByImage"
        class="search-bar__button search-bar__button--voice"
        type="TERTIARY"
        :aria-label="$t('search.image.icon_voice')"
        @click.prevent="handleVoiceSearch">
        <MicroIcon class="search-bar__icon" />
      </ButtonUI>

      <template v-if="shouldShowSearchByImageButton">
        <ButtonUI
          class="search-bar__button search-bar__button--image"
          type="TERTIARY"
          :aria-label="$t('search.image.icon_camera')"
          @click.prevent="handleImageSearch">
          <CameraIcon
            v-if="!shouldShowSearchByImage"
            class="search-bar__icon" />
          <CloseIcon
            v-else
            class="search-bar__icon" />
        </ButtonUI>
        <input
          ref="imageInputFile"
          style="display: none"
          type="file"
          :accept="IMAGE_ACCEPT_TYPES"
          @change="handleImageSearch" />
      </template>

      <TypeaheadList
        v-if="shouldShowTypeahead"
        :term="searchSanitizedTerm"
        :results="{ products, brands, categories }"
        @clearSearch="clearSearch" />
      <SearchByImage
        v-if="shouldShowSearchByImage"
        :search-image-selected="searchImageSelected"
        @closeSearchImage="closeSearchImage" />
    </form>
  </search>
</template>

<script>
  import { ButtonUI } from 'CommonComponents/UI/Atoms';
  import { MagnifyingGlassIcon, MicroIcon, CameraIcon, CloseIcon } from 'CommonComponents/UI/Atoms/Icons';
  import { mapActions, mapGetters } from 'CommonUtils/store/state.js';
  import { useI18n } from 'vue-i18n';
  import { useSearchBarImage } from './Image/useSearchBarImage';
  import { useSearchBarText } from './useSearchBarText';
  import { useSearchBarVoice } from './Voice/useSearchBarVoice';
  import UtilsAnalytics from 'CommonUtils/analytics';

  export default {
    name: 'SearchBar',
    components: {
      ButtonUI,
      CameraIcon,
      CloseIcon,
      MagnifyingGlassIcon,
      MicroIcon,
      SearchByImage: defineAsyncComponent(() => import('./Image/SearchByImage.vue')),
      TypeaheadList: defineAsyncComponent(() => import('./TypeaheadList.vue')),
    },
    setup() {
      const { t } = useI18n();
      const { internals } = mapGetters('page', {
        internals: 'getInternals',
      });
      const prevSearch = internals.value?.req?.query?.s;
      const searchTerm = ref(prevSearch ?? '');
      const products = ref([]);
      const brands = ref([]);
      const categories = ref([]);
      const searchInput = ref();
      const hasFocus = ref(false);
      const { closeTypeahead, openTypeahead, SEARCH_PARAMS, searchSanitizedTerm, shouldShowTypeahead } =
        useSearchBarText({ searchTerm, products, brands, categories, prevSearch });
      const { shouldShowSearchVoice, handleVoiceSearch } = useSearchBarVoice({ searchTerm });
      const {
        closeSearchImage,
        handleImageSearch,
        IMAGE_ACCEPT_TYPES,
        imageInputFile,
        searchImageSelected,
        shouldShowSearchByImage,
        shouldShowSearchByImageButton,
      } = useSearchBarImage({ searchTerm });
      const { clicAndCar } = mapGetters('cart', { clicAndCar: 'getClicAndCar' });
      const { isStoreMode } = mapActions('page', { isStoreMode: 'isStoreMode' });

      const searchPlaceHolder = computed(() => {
        if (isStoreMode.value && clicAndCar.value?.centre_title) {
          return t('search.what_are_you_looking_for_in', { centre: clicAndCar.value.centre_title });
        }
        return t('search.what_are_you_looking_for');
      });

      const handleSearch = () => {
        UtilsAnalytics.sendSearchEvent('text_box');
        redirectSearch();
      };

      const redirectSearch = () => {
        const newUrl = new URL(`${window.location.origin}/search/`);
        newUrl.searchParams.append(SEARCH_PARAMS.S, `${searchSanitizedTerm.value}`);
        newUrl.searchParams.append(SEARCH_PARAMS.STYPE, 'text_box');
        window.location.href = newUrl.href;
      };
      const onFocus = () => {
        hasFocus.value = true;
        openTypeahead();
      };
      const onBlur = () => {
        hasFocus.value = false;
        closeTypeahead();
      };
      const clearSearch = () => {
        searchTerm.value = '';
        if (!hasFocus.value) {
          hasFocus.value = true;
          searchInput.value.focus();
        } else {
          hasFocus.value = false;
          closeTypeahead();
        }
      };

      return {
        brands,
        categories,
        clearSearch,
        closeSearchImage,
        closeTypeahead,
        handleImageSearch,
        handleSearch,
        handleVoiceSearch,
        IMAGE_ACCEPT_TYPES,
        imageInputFile,
        openTypeahead,
        products,
        searchImageSelected,
        searchInput,
        searchPlaceHolder,
        searchSanitizedTerm,
        searchTerm,
        shouldShowSearchByImage,
        shouldShowSearchByImageButton,
        shouldShowSearchVoice,
        shouldShowTypeahead,
        onFocus,
        onBlur,
      };
    },
  };
</script>
