const commonAnalytics = require('./common.analytics.js');

const DEFAULT_PROMOTION_PUSH_OFFSET = 20;

function sendClickOnLink(currentPageName, megadropItemPath) {
  const event = {
    event: 'custom_event',
    event_data: {
      event_category: `megadrop ${currentPageName ?? 'Home'}`,
      event_action: `click on megadrop`,
      event_label: megadropItemPath,
    },
  };

  window?.dataLayer?.push(event);
}

function sendOpenMegadrop(currentPageName) {
  const event = {
    event: 'custom_event',
    event_data: {
      event_category: `megadrop ${currentPageName ?? 'Home'}`,
      event_action: `open megadrop`,
      event_label: 'undefined',
    },
  };

  window?.dataLayer?.push(event);
}

function checkMegadropPromos(megadropArtworks) {
  const arrayOfMegadropSheet = megadropArtworks.length ? megadropArtworks.map((category) => category.sheet?.id) : [];

  const arrayOfMegadropSheetCompositions = megadropArtworks?.flatMap((e) => {
    return e?.sheet?.compositions?.flatMap((comp) => comp?.id || []);
  });

  const artworkIdsMegarop = megadropArtworks?.flatMap((category) =>
    category?.sheet?.compositions?.flatMap((comp) =>
      comp?.artworks?.flatMap((artwork) => artwork?.flatMap((artwork) => artwork?.artwork?.id || [])),
    ),
  );

  let megadropPromos = [];
  if (arrayOfMegadropSheet.length) megadropPromos = megadropPromos.concat(arrayOfMegadropSheet);
  if (arrayOfMegadropSheetCompositions.length) megadropPromos = megadropPromos.concat(arrayOfMegadropSheetCompositions);
  if (artworkIdsMegarop.length) megadropPromos = megadropPromos.concat(artworkIdsMegarop);
  return megadropPromos;
}

function sendPromotionPushMegadropInitial(megadropPromos) {
  let start = 0;
  const offSet = DEFAULT_PROMOTION_PUSH_OFFSET;
  const promos = commonAnalytics.buildSimplePromos(document, megadropPromos, true);

  //MANEJO DE IMPRESIONES
  if (promos?.length) {
    if (window?.dataLayer) {
      const promotionsPush = window.dataLayer.filter((e) => e.event === 'promotions_push');
      const currentPromosMenu = promos.map((promotion) => promotion.id);
      const promotionsList = promotionsPush.flatMap((promotion) => promotion[`promotions_` + promotion.index_pushed]);
      const currentPromotionsListPromos = promotionsList.map((promotion) => promotion.id);
      if (currentPromosMenu.every((promotion) => currentPromotionsListPromos.includes(promotion))) return;
    }
    promos.forEach(function (promo, idx) {
      if (idx % offSet === 0) {
        var payload = {
          event: 'promotions_push',
          index_pushed: window.dataLayer?.filter((e) => e.event === 'promotions_push').length + 1,
        };
        payload['promotions_' + payload.index_pushed] = promos.slice(start, start + offSet);
        window.dataLayer.push(payload);
        start += offSet;
      }
    });
  }
}

module.exports = {
  checkMegadropPromos,
  sendClickOnLink,
  sendOpenMegadrop,
  sendPromotionPushMegadropInitial,
};
