<template>
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M8.03995 11.89C9.98995 11.89 11.5699 10.31 11.5699 8.36V4.53C11.5699 2.58 9.98995 1 8.03995 1C6.08995 1 4.50995 2.58 4.50995 4.53V8.36C4.50995 10.31 6.08995 11.89 8.03995 11.89ZM5.50995 4.53C5.50995 3.13 6.64995 2 8.03995 2C9.42995 2 10.5699 3.14 10.5699 4.53V8.36C10.5699 9.76 9.43995 10.89 8.03995 10.89C6.63995 10.89 5.50995 9.75 5.50995 8.36V4.53Z"
        :fill="color" />
      <path
        id="Vector_2"
        d="M12.68 8.82C12.41 8.76 12.14 8.92 12.07 9.19C11.61 11.06 9.92997 12.37 7.99997 12.37C6.06997 12.37 4.39997 11.07 3.92997 9.2C3.85997 8.93 3.59997 8.77 3.31997 8.84C3.04997 8.91 2.88997 9.18 2.95997 9.45C3.49997 11.6 5.31997 13.13 7.48997 13.34V14.02H5.41997C5.13997 14.02 4.91997 14.24 4.91997 14.52C4.91997 14.8 5.13997 15.02 5.41997 15.02H10.52C10.8 15.02 11.02 14.8 11.02 14.52C11.02 14.24 10.8 14.02 10.52 14.02H8.48997V13.34C10.67 13.13 12.5 11.6 13.04 9.44C13.11 9.17 12.94 8.9 12.67 8.83L12.68 8.82Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'MicroIcon',
    props: {
      width: {
        type: String,
        default: '16',
      },
      height: {
        type: String,
        default: '16',
      },
      fill: {
        type: String,
        default: 'none',
      },
      color: {
        type: String,
        default: '#242424',
      }
    }
  }
</script>