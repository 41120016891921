import { mapActions, mapGetters } from 'CommonUtils/store/state.js';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
import ICON_NAMES from 'CommonUtils/iconNames.js';

const useSearchBarVoice = ({ searchTerm }) => {

  const { isChrome } = useNavigatorDetect();
  const { isSearchVoice } = mapGetters('page', { isSearchVoice: 'getIsSearchVoice' });
  const { showModal, hideAllModals } = mapActions('uiState', {
    showModal: 'showModal',
    hideAllModals: 'hideAllModals',
  });

  const shouldShowSearchVoice = computed(() => {
    return !searchTerm.value.length && isSearchVoice.value && isChrome.value;
  });

  const handleVoiceSearch = () => {
    const searchVoiceModal = {
      name: ICON_NAMES.SEARCH_SPEECH,
      status: true,
    };
    hideAllModals();    
    showModal(searchVoiceModal);
  };

  return {
    handleVoiceSearch,
    shouldShowSearchVoice,
  };
};

export { useSearchBarVoice };