import { onMounted, onUnmounted, ref } from "vue"
import MODAL_TYPES from 'Utils/modalTypes'
import { mapGetters, mapActions } from "CommonUtils/store/state"

const useNewsletterSubmit = () => {
    const { error, message, isLoading } = mapGetters('newsletter', { error: 'getError', message: 'getMessage', isLoading: 'isLoading' })
    const { postNewsletterSub, clearMessage, updateBlurred } = mapActions('newsletter', ['postNewsletterSub', 'clearMessage', 'updateBlurred'])

    const modalVisible = ref(false)
    const showIframe = ref(false)
    const iframeIsLoading = ref(true)
    const privacyIframeRef = ref(null)
    let resizeObserver


    const submitNewsletterForm = () => {
      updateBlurred(true)
      postNewsletterSub()
      modalVisible.value = !error.value
    }
    const closeModal = () => {
      modalVisible.value = false
      showIframe.value = false
      iframeIsLoading.value = true
      privacyIframeRef.value = null
      clearMessage()
    }
    
    const iframeResizeHandler = () => {
      if (!privacyIframeRef.value) return
      privacyIframeRef.value.style.height =
        privacyIframeRef.value.contentWindow.document.body.querySelector(
          '.main__content > div'
        ).scrollHeight + 'px'
    }

    const iframeResizeObserve = () => {
      resizeObserver = new ResizeObserver(iframeResizeHandler)
      resizeObserver.observe(privacyIframeRef.value)
    }
    onUnmounted(() => {
      if (!privacyIframeRef.value) return
      resizeObserver.unobserve(privacyIframeRef.value)
    })

    return {
      message,
      modalVisible,
      showIframe,
      iframeIsLoading,
      privacyIframeRef,
      iframeResizeObserve,
      submitNewsletterForm,
      closeModal,
      isLoading,
      MODAL_TYPES
    }
}

export default useNewsletterSubmit