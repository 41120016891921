const formatShippingPrice = (price, freeText) => {
  return price === '0 €' ? freeText : price;
};

const formatInteger = (value) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, function (c) {
    return '.' + c;
  });
};

const formatFractional = (value) => {
  if (!value) return '00';
  if (value.length === 1) return value + '0';
  return value.substring(0, 2);
};

const formatPrice = (price,type) => {
  let separator = ',';
  if ('string' !== typeof price && price) {
    price = price.toString();
    separator = '.';
  }
  let integerPart = (price || '').split(separator)[0];
  let fractionalPart = (price || '').split(separator)[1];
  let integerPrice = formatInteger(integerPart);
  let floatingPrice = formatFractional(fractionalPart);
  let currency = '€';
  if (currency) {
    currency = currency.replace('EUR', '€');
    currency = currency.replace(' Kilo', '/Kg');
  }
  if (integerPrice === 'Gratis' || integerPrice == 0.0) return 'Gratis';
  let spanIntegerPrice = '<span>' + integerPrice + '</span>';
  let spanFloatingPrice =
    floatingPrice !== '00'
      ? ',<span>' + floatingPrice + '</span>'
      : ',<span>00</span>';

  let spanCurrency = ' <span class="js-currency">' + currency + '</span>';
  let formatedPrice =
    type === 'COMPLEX'
      ? spanIntegerPrice + spanFloatingPrice + spanCurrency
      : spanIntegerPrice + spanFloatingPrice + spanCurrency;
  return formatedPrice;
};

const priceFilters = {
  formatShippingPrice: formatShippingPrice,
  formatPrice: formatPrice
};

export default priceFilters;
