import { mapGetter } from 'CommonUtils/store/state.js';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';

const useSearchBar = () => {
  const isMegadropOpen = mapGetter('uiState/getStatusModalMegadrop');
  const isPlp = mapGetter('page/getIsPlp');
  const isPdp = mapGetter('page/getIsPdp');
  const isApp = mapGetter('page/isApp');
  const isModal = mapGetter('page/isModal');
  const placements = mapGetter('page/getPlacements');
  const { isDesktopDevice } = useNavigatorDetect();
  const isScrollOnTop = ref(true);
  const searchBoxObserver = ref(null);

  const hasCover = (placementList) => {
    return Boolean(placementList?.cover || placementList?.full_cover);
  }
  const isSearchBoxAlwaysVisible = computed(() => {
    const pageHasCover = hasCover(placements.value);
    const isPlpWithCover = isPlp.value && pageHasCover;
    if (isApp.value) return false;
    return isDesktopDevice.value ||
      (
        !isMegadropOpen.value &&
        !isPdp.value &&
        (!isPlp.value || isPlpWithCover)
      );
  });

  const isSearchBoxAlwaysHidden = computed(() => {
    return isApp.value || isModal.value || (!isDesktopDevice.value && isPdp.value);
  })

  const mustShowHeaderSearchIcon = computed(() => {
    if (isSearchBoxAlwaysVisible.value) return false;
    if (isMegadropOpen.value) return true;
    if (isPdp.value) return true;
    if (isPlp.value && !isScrollOnTop.value) return true;

    return false;
  });

  watch(placements, (newPlacements, oldPlacements) => {
    const newCover = hasCover(newPlacements);
    const oldCover = hasCover(oldPlacements);
    const newBand = Boolean(newPlacements?.band);
    const oldBand = Boolean(oldPlacements?.band);
    if ((newCover !== oldCover) || (newBand !== oldBand)) {
      initSearchBoxObserver(newBand);
    }
  });

  watch(isSearchBoxAlwaysVisible, (newVal, oldVal) => {
    if (newVal && searchBoxObserver.value) {
      searchBoxObserver.value.disconnect();
    }
    if (oldVal && !newVal) {
      initSearchBoxObserver(Boolean(placements.value?.band));
    }
  });

  const initSearchBoxObserver = (hasBand = false) => {
    const searchBoxBtn = document.getElementById('searchBoxBtn');
    if(searchBoxObserver.value) {
      searchBoxObserver.value.disconnect();
    }
    if (searchBoxBtn) {
      const topMargin = hasBand ? '-90px 0px 0px 0px' : '-50px 0px 0px 0px';
      searchBoxObserver.value = new IntersectionObserver((entries) => {
        return entries.forEach((entry) => {
          const { isIntersecting } = entry;
          isScrollOnTop.value = isIntersecting;
        });
      }, { root: null, rootMargin: topMargin });
      searchBoxObserver.value.observe(searchBoxBtn);
    }
  }

  return {
    mustShowHeaderSearchIcon,
    isSearchBoxAlwaysVisible,
    isSearchBoxAlwaysHidden,
    isScrollOnTop,
    initSearchBoxObserver,
  }
}

export default useSearchBar;