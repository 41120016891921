<template>
  <div class="cart__container__desktop">
    <TitleCart :label="$t('cart.shopping_cart')" />

    <div
      v-if="!fullItemsCart.length"
      class="empty-cart">
      {{ $t('cart.empty_cart_description') }}
    </div>

    <div
      v-else
      class="wrapper__full__products">
      <div class="wrapper__cart__list">
        <CartList :items="fullItemsCart" />
      </div>

      <CartDetails
        :summary="fullSummaryData"
        :fullDiscountsData="fullDiscountsData" />
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { mapGetter } from 'CommonUtils/store/state';

  import TitleCart from './UI/TitleCart.vue';
  import CartDetails from './UI/CartDetails.vue';
  import CartList from './CartList.vue';

  const cartData = mapGetter('cart/getFullCartData');
  const fullItemsCart = mapGetter('cart/itemsInCart');
  const fullSummaryData = computed(() => cartData.value.summary);
  const fullDiscountsData = computed(() => cartData.value.order_discounts);
</script>

<style lang="less">
  .cart__container__desktop {
    padding-top: .75em;
    
    .title-cart {
      font-size: 1.25em;
      text-align: center;
      font-weight: 400;
      position: absolute;
      top: 1rem;
    } 
  }
</style>