<template>
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M8.71002 8L14.83 1.88C15.03 1.68 15.03 1.37 14.83 1.17C14.63 0.969997 14.32 0.969997 14.12 1.17L8.00002 7.29L1.88002 1.17C1.68002 0.969997 1.37002 0.969997 1.17002 1.17C0.97002 1.37 0.97002 1.68 1.17002 1.88L7.29002 8L1.17002 14.12C0.97002 14.32 0.97002 14.63 1.17002 14.83C1.27002 14.93 1.40002 14.98 1.52002 14.98C1.64002 14.98 1.78002 14.93 1.87002 14.83L7.99002 8.71L14.11 14.83C14.21 14.93 14.34 14.98 14.46 14.98C14.58 14.98 14.72 14.93 14.81 14.83C15.01 14.63 15.01 14.32 14.81 14.12L8.69002 8H8.71002Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'CloseIcon',
    props: {
      width: {
        type: String,
        default: '16',
      },
      height: {
        type: String,
        default: '16',
      },
      fill: {
        type: String,
        default: 'none',
      },
      color: {
        type: String,
        default: '#242424',
      }
    }
  }
</script>