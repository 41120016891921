<template>
  <transition
    :name="disabled ? '' : 'accordion'"
    @before-enter="onBeforeEnter" @enter="onEnter"
    @before-leave="onBeforeLeave" @leave="onLeave"
    @after-leave="onAfterLeave"
    @after-enter="onAfterEnter">
    <slot></slot>
  </transition>  
</template>

<script> export default { name: 'AccordionAnimation' }; </script>
<script setup>
  const emits = defineEmits(['animationFinished']);

  const props = defineProps({ 
    transitionDuration: { type: String, default: '500ms' },
    disabled: { type: Boolean, default: false},
  })

  const onBeforeEnter = el => el.style.height = '0'

  const onEnter = el => el.style.height = el.scrollHeight + 'px';

  const onAfterEnter = el => el.style.height = 'auto';

  const onBeforeLeave = el => el.style.height = el.scrollHeight + 'px';
  
  const onAfterLeave = () => emits('animationFinished');

  const onLeave = el => el.style.height = '0';
</script>

<style>
  .accordion-enter-active,
  .accordion-leave-active {
    transition-property: height, opacity;
    transition-duration: v-bind(transitionDuration);
    transition-timing-function: ease-out;
    overflow: hidden;
  }
  .accordion-enter-from,
  .accordion-leave-to {
    opacity: 0;
  }
</style>