<template>
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M7.99999 11.45C6.33999 11.45 4.98999 10.08 4.98999 8.40001C4.98999 6.72001 6.33999 5.35001 7.99999 5.35001C9.65999 5.35001 11.01 6.72001 11.01 8.40001C11.01 10.08 9.65999 11.45 7.99999 11.45ZM7.99999 6.34001C6.88999 6.34001 5.98999 7.26001 5.98999 8.39001C5.98999 9.52001 6.88999 10.44 7.99999 10.44C9.10999 10.44 10.01 9.52001 10.01 8.39001C10.01 7.26001 9.10999 6.34001 7.99999 6.34001Z"
        :fill="color" />
      <path
        id="Vector_2"
        d="M13.51 13.52H2.48999C1.65999 13.52 0.98999 12.89 0.98999 12.12V5.79001C0.98999 5.02001 1.65999 4.39001 2.48999 4.39001H3.72999C3.91999 4.39001 4.08999 4.28001 4.17999 4.11001L4.57999 3.31001C4.82999 2.80001 5.34999 2.48001 5.91999 2.48001H10.02C10.59 2.48001 11.12 2.81001 11.37 3.32001L11.75 4.11001C11.83 4.28001 12.01 4.39001 12.2 4.39001H13.5C14.33 4.39001 15 5.02001 15 5.79001V12.13C15 12.9 14.33 13.53 13.5 13.53L13.51 13.52ZM2.48999 5.39001C2.21999 5.39001 1.98999 5.57001 1.98999 5.79001V12.13C1.98999 12.35 2.21999 12.53 2.48999 12.53H13.51C13.78 12.53 14.01 12.35 14.01 12.13V5.78001C14.01 5.56001 13.78 5.38001 13.51 5.38001H12.21C11.64 5.38001 11.11 5.05001 10.86 4.54001L10.48 3.75001C10.4 3.58001 10.22 3.47001 10.03 3.47001H5.92999C5.73999 3.47001 5.56999 3.58001 5.47999 3.75001L5.07999 4.55001C4.82999 5.06001 4.30999 5.38001 3.73999 5.38001H2.49999L2.48999 5.39001Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'CameraIcon',
    props: {
      width: {
        type: String,
        default: '16',
      },
      height: {
        type: String,
        default: '16',
      },
      fill: {
        type: String,
        default: 'none',
      },
      color: {
        type: String,
        default: '#242424',
      }
    }
  }
</script>