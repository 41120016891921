<template>
  <button :class="modifier">
    <slot></slot>
  </button>
</template>

<script>
  const BASE_CLASS = 'button-ui';
  const SIZES = ['REGULAR', 'SMALL'];
  const TYPES = ['PRIMARY', 'SECONDARY', 'TERTIARY'];
  const [DEFAULT_SIZE] = SIZES;
  const [DEFAULT_TYPE] = TYPES;

  const type = {
    type: String,
    default: DEFAULT_TYPE,
    validator: (type) => TYPES.includes(type)
  };
  const size = {
    type: String,
    default: DEFAULT_SIZE,
    validator: (size) => SIZES.includes(size)
  };
  
  export default {
    name: 'ButtonUI',
    props: { type, size },
    setup(props) {
      const modifier = computed(() => [
        BASE_CLASS,
        `${BASE_CLASS}--${props.size.toLowerCase()}`,
        `${BASE_CLASS}--${props.type.toLowerCase()}`
      ]);

      return { modifier };
    }
  }
</script>