<template>
  <div
    v-if="!isBuyLater && !item.financial_info"
    :class="['CartBtnUnits', isCheckout ? 'btn-units-checkout' : 'btn-units', {'CartBtnUnits--buttonsHidden': hideSelectors || hidePlusAndMinButtons }]">
    <div v-if="!hideSelectors && !hidePlusAndMinButtons">
      <button
        v-if="item.units === 1"
        :disabled="isLoading"
        @click="redirectDelete">
        <i class="pointer i-delete icon-btn"></i>
      </button>
      <button
        v-else
        :disabled="isLoading"
        :class="{ disabled: isLoading}"
        @click="handleUnits(item, TYPE_ACTION.MINUS, true)">
        -
      </button>
    </div>

    <div v-if="!(hideSelectors && item.units === 1)">
      <p class="variant">
        <span v-if="isLoading">
          <i class="spinner"></i>
        </span>
        <span v-else>
          {{ item.units }}
          <span v-if="hideSelectors">{{ $t('cart.units') }}</span>
        </span>
      </p>
    </div>

    <button
      v-if="!hideSelectors && !hidePlusAndMinButtons"
      :disabled="isLoading || isDisabledPlusBtn"
      :class="{ disabled: isDisabledPlusBtn || isLoading }"
      @click="handleUnits(item, TYPE_ACTION.PLUS, true)">
      +
    </button>
  </div>
</template>

<script>
import CheckoutLogic from 'CommonMixins/checkoutLogic';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [CheckoutLogic],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isDisabledPlusBtn: {
      type: Boolean,
      default: false,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    isBuyLater: {
      type: Boolean,
      default: false,
    },
    hideSelectors: {
      type: Boolean,
      default: false,
    },
    hidePlusAndMinButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      TYPE_ACTION: Object.freeze({
        PLUS: 'PLUS',
        MINUS: 'MINUS',
      }),
    };
  },
  computed: {
    ...mapGetters('cart', {
      isFireFlyCart: 'getIsFireFlyCart',
    }),
  },
  methods: {
    ...mapActions('cart', {
      updateCartItem: 'updateCartItem',
    }),
    async handleUnits(item, action, is_from_checkout) {
      if (item.units === 1 && action === this.TYPE_ACTION.MINUS) return;
      this.isLoading = true;
      try {
        if (this.isFireFlyCart) {
          await this.updateCartItem({ item, action, is_from_checkout });
        } else {
          await this.changeUnits(item, action, is_from_checkout);
        }
      } finally {
        this.isLoading = false;
      }
    },
    redirectDelete() {
      if (this.isBuyLater) {
        this.deleteItemInBuyLater(this.item);
      } else {
        this.deleteItem(this.item);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.CartBtnUnits {
  border: 1px solid #A6ABA6;
  border-radius: 2px;
  display: flex;
  width: 152px;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em 0.75em;

  &--buttonsHidden {
    border: none;
  }

  button {
    font-size: 24px;
    line-height: 1;
    display: block;

    &:disabled {
      cursor: default;
    }

    .i-delete {
      display: block;
      width: .6em;
      height: .6em;
      background-size: contain;
      background-position: center;
    }
  }
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: rgb(129, 129, 129);
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>