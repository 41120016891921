/**
 * Enum for items types.
 * @readonly
 * @enum type of item
 */
const ITEM_TYPES = Object.freeze({
  COMMERCE_ITEM: 'COMMERCE_ITEM',
  ECI_PRIME: 'ECI_PRIME'
});

export default ITEM_TYPES;
