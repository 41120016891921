<template>
  <picture class="image__wrapper">
    <a :href="PdpRedirect.goToPDP(item)">
      <img
        :class="isImgLoaded ? 'show' : ''"
        :title="item.title"
        :image="item.image.url"
        :alt="item.title"
        :src="item.image && item.image.url ? item.image.url : item.default_image"
        :data-src="item.image"
        @error="setErrorImgToSrc"
        @load="isImgLoaded = true" />
    </a>
  </picture>
</template>

<script setup>
  import { shallowRef } from 'vue';
  import PdpRedirect from 'CommonUtils/pdpRedirect.js';

  defineProps({
    item: { type: Object, required: true },
  });

  const ERROR_IMG_HREF = "https://www.elcorteingles.es/sgfm/SGFM/contents/images/common/no-image.png"
  const isImgLoaded = shallowRef(false);

  function setErrorImgToSrc(e) {
    e.target.src = ERROR_IMG_HREF
  }
</script>

<style lang="less" scoped>
  //TODO: Hay que mirar el tipo de animación
  .image__wrapper {
    img {
      opacity: 0;
      transition: 1s;
    }

    img.show {
      opacity: 1;
    }
  }
</style>