<template>
  <div class="popover">
    <div class="popover__target" @mouseover="hover" @mouseout="hoverOut">
      <slot name="target"></slot>
    </div>
    <transition name="popover__fade">
      <div
        class="popover__info"
        :class="classPosition"
        :style="cssVariables"
        v-if="showPopup || show"
        @mouseover="hoverInfo"
        @mouseout="hoverOutInfo"
      >
        <slot name="info"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Popover',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'bottom',
      enum: ['top', 'right', 'bottom', 'left']
    },
    align: {
      type: String,
      default: 'center',
      enum: ['start', 'center', 'end']
    },
    borderColor: {
      type: String,
      default: '#d6d6d6'
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    },
    infoMargin: {
      type: String,
      default: '.5rem'
    }
  },
  data() {
    return {
      showPopup: false,
      timer: '',
      isInInfo: false
    };
  },
  computed: {
    cssVariables() {
      return {
        '--border-color': this.borderColor,
        '--background-color': this.backgroundColor,
        '--info-margin': this.infoMargin
      };
    },
    classPosition() {
      return [
        `popover__info--${this.position}`,
        `popover__info--${this.align}`
      ];
    }
  },
  methods: {
    hover() {
      let vm = this;
      vm.timer = setTimeout(() => vm.showPopover(), 600);
    },
    hoverOut() {
      let vm = this;
      clearTimeout(vm.timer);
      vm.timer = setTimeout(() => !vm.isInInfo && vm.closePopover(), 200);
    },
    hoverInfo() {
      this.isInInfo = true;
    },
    hoverOutInfo() {
      this.isInInfo = false;
      this.hoverOut();
    },
    showPopover() {
      this.showPopup = true;
    },
    closePopover() {
      this.showPopup = false;
    }
  }
};
</script>
