import { mapGetters } from 'CommonUtils/store/state.js';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';

const IMAGE_ACCEPT_TYPES = '.jpeg,.jpg,.png';

const useSearchBarImage = ({ searchTerm }) => {
  const { isDesktopDevice } = useNavigatorDetect();

  const imageInputFile = ref();
  const isOpenSearchImage = ref(false);
  const searchImageSelected = ref(false);

  const { isSearchedImageEnabled } = mapGetters('page', { isSearchedImageEnabled: 'getSearchedImageEnabled' });

  const shouldShowSearchByImageButton = computed(() => {
    return !searchTerm.value.length && isSearchedImageEnabled.value;
  });

  const shouldShowSearchByImage = computed(() => {
    return isOpenSearchImage.value && shouldShowSearchByImageButton.value;
  });

  const handleImageSearch = (event) => {
    const hasFiles = event?.target?.files;
    const openFileMobile = !hasFiles && !isDesktopDevice.value;

    if (isOpenSearchImage.value) return closeSearchImage();
    if (openFileMobile) return imageInputFile.value.click();
    if (hasFiles) searchImageSelected.value = event;
    isOpenSearchImage.value = true;    
  };

  const closeSearchImage = () => {
    isOpenSearchImage.value = false;
  };

  return {
    closeSearchImage,
    handleImageSearch,
    IMAGE_ACCEPT_TYPES,
    imageInputFile,
    searchImageSelected,
    shouldShowSearchByImage,
    shouldShowSearchByImageButton,
  };
};

export { useSearchBarImage };