export const CHAT_TYPES = Object.freeze({
  EXPERT  : 'expert',
  SUPPORT : 'support'
});

export const MESSAGE_TYPES = Object.freeze({
  SUPPORT : 'support',
  EXPERT : 'expert',
  INFO    : 'info',
  USER    : 'user',

});


export const EXPERT_COOKIES = Object.freeze({
  COOKIE_PRIVACY_NAME: 'expert_privacy',
  COOKIE_PRIVACY_VALUE: 1,
  COOKIE_PRIVACY_TIME: 30 * 4 * 1,
});