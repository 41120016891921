<template>
  <div class="cart__container">
    <div
      v-if="isLoading"
      class="loadingCart">
      <Spinner />
    </div>

    <ContainerDesktop />
  </div>
</template>

<script setup>
  import { mapGetter } from 'CommonUtils/store/state.js';
  import Spinner from 'CommonComponents/UI/Spinner.vue';
  import ContainerDesktop from './ContainerDesktop.vue';

  const isLoading = mapGetter('page/getLoadingStatus');
</script>

<style lang="less" scoped>
  .loadingCart {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .text-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      height: 100%;
    }
  }
</style>
