<template>
  <section class="logos">
    <div class="logos__wrapper">

      <div class="logos__section">
        <a :href="expressLink" class="logos__logo" :aria-label="$t('layouts.footer.click_express')"><span class="logos__icon"><Logo.ClickAndExpress/></span></a>
        <a :href="links?.click_and_car" class="logos__logo" :aria-label="$t('layouts.footer.click_car')"><span class="logos__icon"><Logo.ClickAndCar/></span></a>
        <a :href="clickCollectLink" class="logos__logo" :aria-label="$t('layouts.footer.recogida_tienda')"><span class="logos__icon"><Logo.RecogidaEnTienda/></span></a>
      </div>

      <div class="logos__section logos__section--grid">
        <a v-show="!canarias" href="https://www.elcorteingles.es/tarifa-plana-envios/" class="logos__logo"><Logo.EciPlus/><span class="logos__label">{{ $t("layouts.footer.tarifa_plana") }}</span></a>
        <a :href="links?.our_card" class="logos__logo"><i class="logos__icon icon nuestra-tarjeta"></i><span class="logos__label">{{ $t("layouts.footer.nuestra_tarjeta") }}</span></a>
        <a :href="helpLink" class="logos__logo"><i class="logos__icon logos__icon--att-cliente icon att-cliente"></i><span class="logos__label">{{ $t("layouts.footer.atencion_al") }}</span></a>
        <a :href="helpLink" class="logos__logo"><i class="logos__icon icon ayuda"></i><span class="logos__label">{{ helpLabel }}</span></a>
      </div>

    </div>
  </section>
</template>

<script> export default { name: 'Logos' }; </script>
<script setup>
  import * as Logo from './Logos/logos'
  import { mapGetters } from "CommonUtils/store/state"

  const { _internals, sitebar, links } = mapGetters('page', { 
    sitebar: 'getSiteBar',
    _internals: 'getInternals',
    links: 'getFooterLogos'
  });

  const canarias = _internals?.value?.req?.moonshine?.context?.navigation_mode?'/canarias':''

  const expressLink = `https://www.elcorteingles.es${canarias}/info/entrega-2h/`
  const clickCollectLink = `https://www.elcorteingles.es${canarias}/info/click-collect/`
  const helpLink = sitebar?.value?.links?.help
  const helpLabel = sitebar?.value?.labels?.help
</script>