<template>
  <div class="toast toast-position">
    <transition-group
      name="toast"
      tag="div"
      type="transition">
      <div
        v-for="m in messages"
        :key="m.id"
        class="toast-message toast-type"
        role="note"
        @mouseenter="pauseMessage(m.id)"
        @mouseleave="resumeMessage(m.id)"
        @touchstart="pauseMessage(m.id)"
        @touchend="resumeMessage(m.id)">
        <div
          class="toast-message-text"
          v-html="m.text"></div>

        <button
          class="toast-button"
          aria-label="Close"
          type="button"
          @click="() => close(m.id)"></button>
      </div>
    </transition-group>
  </div>
</template>

<script>
  import { mapAction, mapState } from 'CommonUtils/store/state.js';
  export default {
    name: 'ToastMessage',

    setup() {
      const messages = mapState('toastMessageState', 'messages');
      const removeMessage = mapAction('toastMessageState', 'removeMessage');
      const pauseMessage = mapAction('toastMessageState', 'pauseMessage');
      const resumeMessage = mapAction('toastMessageState', 'resumeMessage');

      const close = (id) => {
        removeMessage(id);
      };
      return {
        messages,
        close,
        pauseMessage,
        resumeMessage,
        removeMessage,
      };
    },
  };
</script>
