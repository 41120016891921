import { debounce } from 'CommonUtils/utilities.js';
import { mapAction, mapActions, mapGetters } from 'CommonUtils/store/state.js';
import { useI18n } from 'vue-i18n';
import UtilsUrl from 'CommonUtils/url.js';
const SEARCH_MIN_CHARACTERS = 3;
const SEARCH_DEBOUNCE_MS = 150;
const SEARCH_PARAMS = {
  S: 's',
  STYPE: 'stype',
};

const useSearchBarText = ({ searchTerm, products, brands, categories, prevSearch }) => {
  const { t } = useI18n();

  const addToastMessage = mapAction('toastMessageState', 'addMessage');
  const { isNewTypeaheadEnabled, getVerticalName, isServer } = mapGetters('page', {
    isNewTypeaheadEnabled: 'getNewTypeAheadConfig',
    getVerticalName: 'getVerticalName',
    isServer: 'isServer',
  });
  const { getSearchProducts, getSearchProductsV2 } = mapActions('page', {
    getSearchProducts: 'getSearchProducts',
    getSearchProductsV2: 'getSearchProductsV2',
  });

  const searchSanitizedTerm = computed(() => {
    if (!searchTerm.value) return '';
    return UtilsUrl.sanitize(searchTerm.value) ?? '';
  });

  const showTypeahead = ref(false);
  const shouldShowTypeahead = computed(() => {
    return prevSearch !== searchTerm.value && (searchSanitizedTerm.value.length || showTypeahead.value);
  });

  const openTypeahead = () => (showTypeahead.value = true);
  const closeTypeahead = () => (showTypeahead.value = false);

  const getFormattedProducts = (products) => {
    return products.map((product) => {
      const { url, label } = product;
      const newUrl = new URL(`${window.location.origin}${url}`);
      const selectedLiteral = label;
      const sParamValue = selectedLiteral;

      if (newUrl.searchParams.get(SEARCH_PARAMS.S)) newUrl.searchParams.delete(SEARCH_PARAMS.S);
      newUrl.searchParams.append(SEARCH_PARAMS.S, sParamValue);
      product.url = `${newUrl.pathname}${newUrl.search}`;

      return product;
    });
  };

  const getFormattedbrands = (brands) => {
    return brands.map((brand) => {
      const { url, label } = brand;
      const newUrl = new URL(`${window.location.origin}${url}`);
      const selectedLiteral = label.replace(/\s/g, '_');
      const sParamValue = `${searchSanitizedTerm.value}>${selectedLiteral}`;

      if (newUrl.searchParams.get(SEARCH_PARAMS.S)) newUrl.searchParams.delete(SEARCH_PARAMS.S);
      newUrl.searchParams.append(SEARCH_PARAMS.S, sParamValue);
      brand.url = `${newUrl.pathname}${newUrl.search}`;
      return brand;
    });
  };

  const sendErrorMessage = () => {
    const errorMessage = t('checkout.errors.CRT004');
    const payloadError = `
      <div class="toast-icon-wrapper">
        <i class="toast-error"></i>
      </div>
      <div> ${errorMessage} </div>`;
    addToastMessage(payloadError);
  };

  const searchV1 = (term) => {
    if (isServer.value) return;
    const search = debounce((term) => {
      getSearchProducts({ term })
        .then((response) => {
          products.value = getFormattedProducts(response.products);
          brands.value = getFormattedbrands(response.brands);
          categories.value = response.categories;
        })
        .catch(() => {
          sendErrorMessage();
          return;
        });
    }, SEARCH_DEBOUNCE_MS);
    return search(term);
  };

  const searchV2 = (term) => {
    if (isServer.value) return;
    const search = debounce((term) => {
      getSearchProductsV2({ term, query: { v: getVerticalName.value } })
        .then((response) => {
          products.value = response.keywords || [];
          brands.value = response.brands || [];
          categories.value = response.categories;
        })
        .catch((err) => {
          sendErrorMessage(err);
          return;
        });
    }, SEARCH_DEBOUNCE_MS);
    return search(term);
  };

  watchEffect(() => {
    if (searchSanitizedTerm.value.length < SEARCH_MIN_CHARACTERS && searchSanitizedTerm.value !== prevSearch) {
      products.value = [];
      brands.value = [];
      categories.value = [];
      return;
    }
    if (isNewTypeaheadEnabled.value) {
      searchV2(searchSanitizedTerm.value);
      return;
    }
    searchV1(searchSanitizedTerm.value);
    return;
  });

  return {
    closeTypeahead,
    openTypeahead,
    SEARCH_PARAMS,
    searchSanitizedTerm,
    shouldShowTypeahead,
  };
};

export { useSearchBarText };
