<template>
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M22.17 21.12L17.46 16.41C18.795 14.835 19.605 12.81 19.605 10.59C19.605 5.62503 15.57 1.59003 10.605 1.59003C5.63998 1.59003 1.60498 5.64003 1.60498 10.59C1.60498 15.54 5.63998 19.59 10.605 19.59C12.81 19.59 14.835 18.795 16.41 17.46L21.12 22.17C21.27 22.32 21.465 22.395 21.645 22.395C21.825 22.395 22.035 22.32 22.17 22.17C22.47 21.87 22.47 21.405 22.17 21.105V21.12ZM10.605 18.09C6.46498 18.09 3.10498 14.73 3.10498 10.59C3.10498 6.45003 6.46498 3.09003 10.605 3.09003C14.745 3.09003 18.105 6.45003 18.105 10.59C18.105 14.73 14.745 18.09 10.605 18.09Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'MagnifyingGlassIcon',
    props: {
      width: {
        type: String,
        default: '24',
      },
      height: {
        type: String,
        default: '24',
      },
      fill: {
        type: String,
        default: 'none',
      },
      color: {
        type: String,
        default: '#242424',
      }
    }
  }
</script>