import { mapActions, mapGetters } from 'vuex';
import UtilsCart from 'CommonUtils/formatCartObject';
import UtilsAnalytics from 'CommonUtils/analytics';
import CartActionsMixin from 'CommonMixins/cartActionsLogic';

const checkoutLogic = {
  data() {
    return {
      ITEMS_PER_PAGE: 3,
    };
  },
  mixins: [CartActionsMixin],
  computed: {
    ...mapGetters('buyLater', {
      getBuyLater: 'getBuyLater',
      totalCount: 'getTotalCount',
    }),
    ...mapGetters('cart', {
      getFullCartData: 'getFullCartData',
      getResponse: 'getApiResponse',
    }),
  },
  methods: {
    async deleteItem({ id, data_layer }) {
      const line_items = [
        {
          id: id,
          reference: '',
          store_id: '',
          units: 0,
        },
      ];

      if (data_layer?.motion_childs) {
        this.addSofaConfiguratorItemsForDeletion(line_items, data_layer);
      }

      await this.sendDeleteItemCart(line_items, true);
      UtilsAnalytics.sendRemoveFromCartClick(data_layer);
    },
    addSofaConfiguratorItemsForDeletion(line_items, dataLayer) {
      const motionChildsItems = dataLayer.motion_childs.split(',');
      const currentCartItems = this.getFullCartData.items
        .filter((cartItem) => motionChildsItems.includes(cartItem.reference))
        .map((filteredItem) => {
          return { reference: filteredItem.reference, id: filteredItem.id };
        });
      if (currentCartItems?.length) {
        currentCartItems.forEach((cartItem) => {
          line_items.push({ id: cartItem.id, reference: cartItem.reference, store_id: '', units: 0 });
        });
      }
    },
    handleShippingClick({ item, shippingMethod }) {
      //TODO: Dependiendo del tipo de envío, hay que pasar unos datos u otros, clic_and_car necesita el centro y el tramo horario
      //{"line_items":[{"id":"ci439662593044","store_id":"158","units":1,"reference":"001029512104422005"}],
      //"shipping_methods":[{"shipping_method":"click_and_car","commerce_items":["ci439662593044"]}],"click_and_car":{"general_centre":"0002"}}
      //TODO: eci_express también

      const newItem = {
        shipping_method: shippingMethod,
        commerce_items: [item.id],
      };

      this.postCart({ shipping_methods: newItem, is_from_checkout: true });
    },
    async handleEciExpress({ item, postalCode }) {
      const newItem = {
        shipping_method: 'eci_express',
        commerce_items: [item.id],
      };
      const eciExpress = {
        postal_code: postalCode,
      };

      await this.postCart({
        shipping_methods: newItem,
        eci_express: eciExpress,
        is_from_checkout: true,
      });

      if (!this.getResponse.error) this.getTimeSlot();
    },
    async handleClickNCar({ item, centerId }) {
      const newItem = {
        shipping_method: 'click_and_car',
        commerce_items: [item.id],
      };
      const clickNCar = {
        general_centre: centerId,
      };

      await this.postCart({
        shipping_methods: newItem,
        click_and_car: clickNCar,
        is_from_checkout: true,
        // line_items: line_item
      });
      if (!this.getResponse.error) this.getTimeSlotClicknCar();
    },
    handleSlotTimes({ item, slotTime }) {
      const newItem = {
        shipping_method: 'eci_express',
        commerce_items: [item.id],
      };
      const eciExpress = {
        time_slot: {
          end_date: slotTime.end_date,
          start_date: slotTime.start_date,
        },
      };
      this.postCart({
        shipping_methods: newItem,
        eci_express: eciExpress,
        is_from_checkout: true,
      });
    },
    handleClickCarSlotTimes({ item, slotTime }) {
      const newItem = {
        shipping_method: 'click_and_car',
        commerce_items: [item.id],
      };
      const clickCar = {
        time_slot: {
          end_date: slotTime.end_date,
          start_date: slotTime.start_date,
        },
      };
      this.postCart({
        shipping_methods: newItem,
        click_and_car: clickCar,
        is_from_checkout: true,
      });
    },
    changeUnits(item, action, is_from_checkout) {
      const CURRENT_UNITS = UtilsCart.mapperUnitsObject(item, action, this.TYPE_ACTION);
      this.sendUpdateItemCart({ line_items: CURRENT_UNITS.line_items }, is_from_checkout);
      UtilsAnalytics.sendChangeUnitsInCart(CURRENT_UNITS.line_items[0].data_layer, action);
    },
    async deleteItemInBuyLater(item) {
      const currentSku = { reference: item.sku_buy_later };
      await this.deleteBuyLater(currentSku);
      await this.shouldFetch();
    },
    async shouldFetch() {
      let previousPage = Math.ceil(this.getBuyLater.length / this.ITEMS_PER_PAGE);
      if (this.totalCount <= this.ITEMS_PER_PAGE) previousPage = 1;
      if (this.totalCount >= 2) {
        const payload = {
          currentPage: previousPage,
          itemsPerPage: this.ITEMS_PER_PAGE,
        };
        await this.getBuyLaterProducts(payload);
      }
    },
    async handleAddCartProduct(item, isBuyLater = false) {
      const currentSku = { sku: item.full_object.selected_sku_buy_later };

      const cartObject = {
        product: item.full_object,
        currentSize: currentSku,
        cartData: this.getFullCartData,
        shipping_method: true
      };
      const ADD_PRODUCT_TO_CART = await UtilsCart.formatCartObject(cartObject);

      /**
       * * Lógica de añadir al carrito de la compra desde el listado de comprar más tarde
       * ? Si existe el producto en el carrito de la compra se elimina del listado de comprar más tarde y no se añade otra vez al carrito
       * ? Si no existe el producto en el carrito de la compra se elimina listado de comprar más tarde y se añade al carrito
       */

      const existProductInCart = await this.existProductInCart(item);
      await this.deleteItemInBuyLater(item);

      if (!existProductInCart) {
        await this.handleBuyLaterAddToCart({
          line_items: ADD_PRODUCT_TO_CART.line_items,
          is_from_checkout: true,
          is_buy_later: isBuyLater,
        });
      }
    },
    existProductInCart(item) {
      return this.getFullCartData.items.length
        ? this.getFullCartData.items.find((product) => product.reference === item.id)
        : null;
    },
    async handleSaveLater(item) {
      const previousCount = this.totalCount;
      /**
       * * Lógica de añadir al listado de comprar más tarde
       * ? Si existe el producto en la lista de comprar más tarde se elimina del carrito y no se añade otra vez a listado
       * ? Si no existe el producto en la lista de comprar más tarde se elimina del carrito y se añade en el listado
       */

      await this.deleteItem(item);

      await this.addBuyLater(item);

      if (previousCount < this.ITEMS_PER_PAGE) {
        const payload = {
          currentPage: 1,
          itemsPerPage: this.ITEMS_PER_PAGE,
        };
        this.getBuyLaterProducts(payload);
      }
    },
    ...mapActions('cart', {
      deleteItemByCart: 'deleteItemByCart',
      postCart: 'postCart',
      getTimeSlot: 'getTimeSlot',
      getTimeSlotClicknCar: 'getTimeSlotClicknCar',
    }),
    ...mapActions('buyLater', {
      getBuyLaterProducts: 'getBuyLaterProducts',
      addBuyLater: 'addBuyLater',
      resetState: 'resetState',
      deleteBuyLater: 'deleteBuyLater',
    }),
  },
  computed: {
    ...mapGetters('buyLater', {
      getBuyLater: 'getBuyLater',
      totalCount: 'getTotalCount',
      isLoadingBuyLater: 'getLoadingBuyLater',
    }),
    ...mapGetters('cart', {
      getFullCartData: 'getFullCartData',
      getResponse: 'getApiResponse',
    }),
  },
};

export default checkoutLogic;
