<template>
  <section class="newsletter">
    <div
      class="newsletter__wrapper"
      :class="{ newsletter__wrapper__no_header_line: hideHeaderLine }">
      <form class="newsletter__form">
        <template v-if="!isDesigners">
          <p class="newsletter__form-title">
            {{ $t('footer.newsletter.title') }}
          </p>
          <p class="newsletter__form-subtitle">
            {{ $t('footer.newsletter.subtitle') }}
          </p>
        </template>
        <template v-else>
          <p class="newsletter__form-title">
            {{ $t('footer.newsletter.designers.title') }}
          </p>
        </template>
        <div class="newsletter__form-email">
          <input
            v-model.lazy="formEmail"
            class="newsletter__form-email-input"
            :class="{ 'newsletter__form-email-input--error': showEmailError }"
            :placeholder="$t('global.email')"
            @blur="formEmailBlur" />
          <button
            class="newsletter__form-email-button"
            @click.prevent="submitNewsletterForm">
            {{ $t('global.send') }}
          </button>
          <div
            v-if="showEmailError"
            class="newsletter__form-error newsletter__form-error--email">
            {{ emailError }}
          </div>
        </div>
        <div
          class="newsletter__form-privacy"
          @click.stop="showIframe = !formPrivacyChecked">
          <input
            id="privacy-input"
            v-model="formPrivacyChecked"
            class="newsletter__form-privacy-input"
            type="checkbox" />
          <span
            class="newsletter__form-privacy-check"
            :class="{
              'newsletter__form-privacy-check--error': showPrivacyError,
            }"
            role="checkbox"
            :aria-checked="formPrivacyChecked"
            tabindex="0"
            aria-labelledby="privacy-label"
            @click.stop="[(formPrivacyChecked = !formPrivacyChecked), (showIframe = formPrivacyChecked)]"></span>
          <label
            id="privacy-label"
            class="newsletter__form-privacy-label"
            for="privacy-input">{{ $t('footer.newsletter.privacy.start') }}
            <a
              class="newsletter__form-privacy-link"
              href="javascript:void(0)"
              @click.stop="showIframe = true">{{ $t('footer.newsletter.privacy.link') }}</a>
            {{ $t('footer.newsletter.privacy.end') }}</label>
          <div
            v-if="showPrivacyError"
            class="newsletter__form-error newsletter__form-error--privacy">
            {{ privacyError }}
          </div>
        </div>
      </form>
    </div>
  </section>

  <template v-if="isClientMounted">
    <Teleport to="#Layout-modal-newsletter-feedback">
      <Modal
        v-if="modalVisible || showIframe"
        name="news_modal"
        :showCloseButton="true"
        transitionClass="slide-fade"
        :mainClass="`newsletter__modal ${showIframe ? MODAL_TYPES.SCREEN_CENTERED_BIG : MODAL_TYPES.SCREEN_CENTERED}`"
        @close="closeModal">
        <template #body>
          <Spinner
            v-if="isLoading || (showIframe && iframeIsLoading)"
            containerClass="-center" />
          <iframe
            v-if="showIframe"
            ref="privacyIframeRef"
            :src="privacyLink"
            frameborder="0"
            @load="iframeIsLoading = false"></iframe>
          <div
            v-else
            class="newsletter__modal-message">
            {{ message }}
          </div>
        </template>
        <template #footer>
          <button
            class="newsletter__modal-button"
            @click="closeModal">
            {{ $t('client.components.modals.base.aceptar') }}
          </button>
        </template>
      </Modal>
    </Teleport>
  </template>
</template>

<script>
  import { inject } from 'vue';
  import Modal from 'CommonComponents/Modal/Modal.vue';
  import Spinner from 'CommonComponents/UI/Spinner.vue';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import useNewsletterForm from 'Composables/useNewsletterForm.js';
  import useNewsletterSubmit from 'Composables/useNewsletterSubmit.js';
  import { mapGetters } from 'CommonUtils/store/state';

  export default {
    name: 'Newsletter',
    components: { Modal, Spinner },
    setup() {
      const { isClientMounted } = useNavigatorDetect();
      const { isDesigners } = mapGetters('page', { isDesigners: 'isDesigners' });
      const hideHeaderLine = inject('hasBazaarVoice', false);

      return {
        ...useNewsletterForm(),
        ...useNewsletterSubmit(),
        hideHeaderLine,
        isClientMounted,
        isDesigners,
      };
    },
  };
</script>
