<template>
  <svg version="1.2" baseProfile="tiny" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 202.2 17.5" xml:space="preserve" style="min-height: 17px; margin-bottom: 1px;">
  <g>
    <g>
      <g>
        <polygon fill="#008C2E" points="180.6,15.8 199.9,10.4 180.6,4.9 			"/>
      </g>
    </g>
    <g>
      <path fill="#242424" d="M9.3,15.6l-1.9-3.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0H4.5v3.2H2.4v-11H7c1.4,0,2.5,0.3,3.3,1
        c0.8,0.7,1.2,1.6,1.2,2.8c0,0.9-0.2,1.6-0.6,2.2c-0.4,0.6-0.9,1.1-1.6,1.4l2.4,3.6H9.3z M4.5,10.6H7c0.8,0,1.4-0.2,1.8-0.5
        c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.2-1.2-0.6-1.5C8.4,6.7,7.8,6.5,7,6.5H4.5V10.6z"/>
      <path fill="#242424" d="M21.8,4.7v1.8h-5.9v2.7h5.3V11h-5.3v2.8H22v1.8h-8.2v-11H21.8z"/>
      <path fill="#242424" d="M30.7,6.8c-0.5-0.3-1.1-0.4-1.6-0.4c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.8,0.4-1.2,0.8
        c-0.3,0.3-0.6,0.7-0.8,1.2s-0.3,0.9-0.3,1.4s0.1,1,0.3,1.4c0.2,0.4,0.4,0.8,0.8,1.2c0.3,0.3,0.7,0.6,1.2,0.8
        c0.4,0.2,0.9,0.3,1.4,0.3c0.5,0,1.1-0.1,1.6-0.3c0.5-0.2,1-0.5,1.4-0.9l1.2,1.4c-0.6,0.6-1.2,1-2,1.4c-0.8,0.3-1.5,0.5-2.3,0.5
        c-0.8,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.3-0.7-1.8-1.2S24,13,23.7,12.3c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2
        c0.3-0.7,0.7-1.3,1.2-1.8C25.4,5.7,26,5.3,26.8,5c0.7-0.3,1.5-0.4,2.3-0.4c0.8,0,1.6,0.2,2.3,0.5c0.8,0.3,1.4,0.7,1.9,1.3L32,7.9
        C31.6,7.5,31.2,7.1,30.7,6.8z"/>
      <path fill="#242424" d="M39.8,4.6c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.7,1.9,1.2c0.5,0.5,0.9,1.1,1.2,1.8
        c0.3,0.7,0.4,1.4,0.4,2.2s-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.3-1.2,1.8c-0.5,0.5-1.1,0.9-1.9,1.2c-0.7,0.3-1.5,0.4-2.3,0.4
        s-1.6-0.1-2.3-0.4c-0.7-0.3-1.3-0.7-1.9-1.2c-0.5-0.5-0.9-1.1-1.2-1.8s-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2
        c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.5,1.1-0.9,1.9-1.2C38.2,4.7,38.9,4.6,39.8,4.6z M39.8,6.5c-0.5,0-1,0.1-1.4,0.3s-0.8,0.4-1.2,0.8
        s-0.6,0.7-0.8,1.2c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.5c0.2,0.4,0.5,0.8,0.8,1.2s0.7,0.6,1.2,0.8
        c0.5,0.2,0.9,0.3,1.4,0.3s1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.2-0.8s0.6-0.7,0.8-1.2s0.3-0.9,0.3-1.5c0-0.5-0.1-1-0.3-1.4
        s-0.5-0.8-0.8-1.2S41.7,7,41.2,6.8C40.8,6.6,40.3,6.5,39.8,6.5z"/>
      <path fill="#242424" d="M54.5,10.2h1.8v4.1c-0.3,0.2-0.6,0.4-0.9,0.6s-0.7,0.3-1.1,0.5c-0.4,0.1-0.8,0.2-1.2,0.3s-0.8,0.1-1.2,0.1
        c-0.8,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.3-0.7-1.8-1.2c-0.5-0.5-0.9-1.1-1.2-1.8s-0.4-1.4-0.4-2.2s0.2-1.5,0.5-2.2
        c0.3-0.7,0.7-1.3,1.3-1.8c0.5-0.5,1.2-0.9,1.9-1.2c0.7-0.3,1.5-0.4,2.3-0.4c0.8,0,1.6,0.1,2.3,0.4c0.8,0.3,1.4,0.7,2,1.1l-1.2,1.5
        c-0.4-0.4-0.9-0.7-1.4-0.9c-0.5-0.2-1.1-0.3-1.7-0.3c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.4-1.2,0.8c-0.3,0.3-0.6,0.7-0.8,1.2
        c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.5c0.2,0.4,0.5,0.8,0.8,1.2s0.7,0.6,1.2,0.8c0.5,0.2,1,0.3,1.5,0.3
        c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.3,1.2-0.5V10.2z"/>
      <path fill="#242424" d="M60.9,4.7v11h-2.1v-11H60.9z"/>
      <path fill="#242424" d="M68.2,4.7c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.7,1.8,1.1c0.5,0.5,0.9,1.1,1.2,1.7
        c0.3,0.7,0.4,1.4,0.4,2.2c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.8,1.1c-0.7,0.3-1.5,0.4-2.3,0.4
        h-4.5v-11H68.2z M68.2,13.8c0.5,0,1-0.1,1.4-0.3s0.8-0.4,1.1-0.7c0.3-0.3,0.6-0.7,0.7-1.1c0.2-0.4,0.3-0.9,0.3-1.5
        c0-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.4-0.8-0.8-1.2S70,6.9,69.6,6.8s-0.9-0.3-1.4-0.3h-2.4v7.3H68.2z"/>
      <path fill="#242424" d="M82.9,13.3h-5.4l-1,2.3h-2.2l4.8-11h2.2l4.7,11h-2.3L82.9,13.3z M82.1,11.5l-1.9-4.6l-1.9,4.6H82.1z"/>
      <path fill="#242424" d="M100.2,4.7v1.8h-5.9v2.7h5.3V11h-5.3v2.8h6.1v1.8h-8.2v-11H100.2z"/>
      <path fill="#242424" d="M104.5,4.7l5.7,7.5V4.7h2.1v11h-2l-5.7-7.5v7.5h-2.1v-11H104.5z"/>
      <path fill="#242424" d="M126.2,4.7v1.9h-3.4v9.1h-2.1V6.5h-3.4V4.7H126.2z"/>
      <path fill="#242424" d="M129.8,4.7v11h-2.1v-11H129.8z"/>
      <path fill="#242424" d="M140.5,4.7v1.8h-5.9v2.7h5.3V11h-5.3v2.8h6.1v1.8h-8.2v-11H140.5z"/>
      <path fill="#242424" d="M144.8,4.7l5.7,7.5V4.7h2.1v11h-2l-5.7-7.5v7.5h-2.1v-11H144.8z"/>
      <path fill="#242424" d="M159.8,4.7c0.8,0,1.6,0.1,2.3,0.4s1.3,0.7,1.8,1.1s0.9,1.1,1.2,1.7c0.3,0.7,0.4,1.4,0.4,2.2
        c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.2-1.2,1.7s-1.1,0.9-1.8,1.1c-0.7,0.3-1.5,0.4-2.3,0.4h-4.5v-11H159.8z M159.8,13.8
        c0.5,0,1-0.1,1.4-0.3s0.8-0.4,1.1-0.7c0.3-0.3,0.6-0.7,0.7-1.1c0.2-0.4,0.3-0.9,0.3-1.5c0-0.5-0.1-1-0.3-1.5
        c-0.2-0.5-0.4-0.8-0.8-1.2s-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.4-0.3h-2.4v7.3H159.8z"/>
      <path fill="#242424" d="M174.5,13.3h-5.4l-1,2.3h-2.2l4.8-11h2.2l4.7,11h-2.3L174.5,13.3z M173.7,11.5l-1.9-4.6l-1.9,4.6H173.7z"
        />
    </g>
  </g>
  </svg>
</template>

<script> export default { name: 'RecogidaEnTienda' }; </script>