<script>
  import { defer } from 'CommonUtils/operations/defer';
  import { ref, useSlots, watch } from 'vue';

  export default {
    name: 'DeferComponent',
    props: {
      renderOnMount: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const slots = useSlots();
      const deferredRendering = ref(props.renderOnMount);

      watch(() => slots.default?.()[0].type, () => {
        // Check if slot content type is not vue's empty node type
        if (slots.default?.()[0].type !== Symbol.for('v-cmt')) {
          defer(() => {
            deferredRendering.value = true;
          }, true);
        } else {
          deferredRendering.value = false;
        }
      })

      return () => deferredRendering.value && slots.default ? slots.default() : '';
    },
  };
</script>
