<template v-if="isServer">
  <div class="hidden">
    <iframe 
      v-show="false"
      id="sess" 
      :src="sessionUrl"></iframe>
    <iframe 
      v-show="false"
      id="oidc" 
      :src="oidcUrl"></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isServer: { type: Boolean }
  },
  computed: {
    ...mapGetters('page', {
        sitebar: 'getSiteBar',
        isApp: 'isApp',
        isAMV: 'getIsAMV',
    }),
    oidcUrl(){
      const url = this.sitebar.links._oidc;
      if(this.isApp) return url + '&app=1';
      if(this.isAMV) return url + '&AMV=1';
      return url;
    },
    sessionUrl(){
      return this.sitebar.links._session;
    }
  }
}
</script>

<style>

</style>
