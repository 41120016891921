<template>
  <svg version="1.2" baseProfile="tiny" id="click_and_car" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px" viewBox="0 0 123.1 12.2" xml:space="preserve">
    <g class="click">
      <path fill="#B9B9B9" d="M0,6.2L0,6.2C0,2.8,2.5,0,6.2,0c2.2,0,3.6,0.7,4.7,1.8L9.2,3.7c-0.9-0.8-1.8-1.3-3-1.3
        c-2,0-3.4,1.7-3.4,3.7l0,0c0,2.1,1.4,3.7,3.4,3.7c1.3,0,2.2-0.6,3.1-1.4l1.7,1.7c-1.2,1.4-2.5,2.2-4.8,2.2C2.6,12.2,0,9.6,0,6.2z"
        />
      <path fill="#B9B9B9" d="M13,0.2h2.6v9.5h5.9v2.4H13V0.2z"/>
      <path fill="#B9B9B9" d="M23.2,0.2h2.6v11.8h-2.6V0.2z"/>
      <path fill="#B9B9B9" d="M27.9,6.2L27.9,6.2c0-3.4,2.5-6.2,6.2-6.2c2.2,0,3.6,0.7,4.7,1.8l-1.7,1.9c-0.9-0.8-1.8-1.3-3-1.3
        c-2,0-3.4,1.7-3.4,3.7l0,0c0,2.1,1.4,3.7,3.4,3.7c1.3,0,2.2-0.6,3.1-1.4l1.7,1.7c-1.3,1.4-2.6,2.2-4.9,2.2
        C30.5,12.2,27.9,9.6,27.9,6.2z"/>
      <path fill="#B9B9B9" d="M40.6,0.2h2.6v5.1L48,0.2h3.2l-4.8,5.1l5.1,6.8h-3.2L44.6,7l-1.3,1.4v3.6h-2.6L40.6,0.2
        C40.7,0.2,40.6,0.2,40.6,0.2z"/>
    </g>
    <g class="and-collect">
      <path fill="#B9B9B9" d="M59.4,10.9c-0.9,0.8-2.1,1.3-3.5,1.3c-2.3,0-3.9-1.3-3.9-3.4l0,0c0-1.5,0.8-2.6,2.3-3.2
        c-0.6-0.8-0.8-1.6-0.8-2.4l0,0c0-1.7,1.3-3.1,3.6-3.1c2,0,3.3,1.3,3.3,3l0,0c0,1.6-1,2.5-2.4,3.1l1.6,1.6c0.4-0.6,0.8-1.3,1.2-2.1
        l1.9,1c-0.5,0.8-0.9,1.7-1.6,2.5l1.7,1.7l-1.9,1.2L59.4,10.9z M58,9.4l-2.3-2.3c-0.8,0.4-1.1,0.9-1.1,1.6l0,0
        c0,0.8,0.7,1.4,1.7,1.4C56.9,10,57.5,9.8,58,9.4z M58.2,3.2L58.2,3.2c0-0.7-0.5-1.1-1.1-1.1c-0.7,0-1.2,0.5-1.2,1.2l0,0
        c0,0.6,0.2,0.9,0.7,1.5C57.6,4.3,58.2,3.9,58.2,3.2z"/>
      <path fill="#B9B9B9" d="M63.8,6.2L63.8,6.2c0-3.4,2.5-6.2,6.2-6.2c2.2,0,3.6,0.7,4.7,1.8l-1.7,1.9c-0.9-0.8-1.8-1.3-3-1.3
        c-2,0-3.4,1.7-3.4,3.7l0,0c0,2.1,1.4,3.7,3.4,3.7c1.3,0,2.2-0.6,3.1-1.4l1.7,1.7c-1.2,1.4-2.5,2.2-4.8,2.2
        C66.4,12.2,63.8,9.6,63.8,6.2z"/>
      <path fill="#B9B9B9" d="M80.1,0.2h2.4l5.1,11.9h-2.7l-1.1-2.7h-5l-1.1,2.7H75L80.1,0.2z M82.9,7.1l-1.6-3.9l-1.6,3.9H82.9z"/>
      <path fill="#B9B9B9" d="M89.4,0.2h5.4c1.5,0,2.7,0.4,3.5,1.2c0.6,0.6,1,1.6,1,2.7l0,0c0,1.9-1,3.1-2.5,3.6l2.9,4.2h-3l-2.5-3.8
        h-2.1v3.8h-2.6L89.4,0.2L89.4,0.2z M94.7,5.9c1.3,0,2-0.7,2-1.7l0,0c0-1.1-0.8-1.7-2.1-1.7H92v3.3H94.7z"/>
    </g>
    <polygon class="cnc-eci-logo" fill="#B6B5B5" points="102.5,12 123.1,6.2 102.5,0.4 "/>
  </svg>
</template>

<script> export default { name: 'ClickAndCar' }; </script>