<template>
  <div
    ref="root"
    class="eci_salesforce_chat"
    data-test-id="eci_salesforce_chat" />
</template>

<script setup>
import { onMounted, ref } from 'vue';

// INPUTS PROPS
const props = defineProps({
  scriptSrc: {
    type: String,
    required: true,
  },
  orgId: {
    type: String,
    required: true,
  },
  eswConfigDevName: {
    type: String,
    required: true,
  },
  siteUrl: {
    type: String,
    required: true,
  },
  scrtUrl: {
    type: String,
    required: true,
  },
  language: {
    type: String,
    default: 'es',
  }
});

// INPUT EVENTS
const input_events = {
  OPEN_CHAT: 'open-chat',
};

// OUTPUT EVENTS
const output_events = {
  AVAILABLE: 'available',
};


const emit = defineEmits([
  'available',
]);

const root = ref(null);

onMounted(() =>  {
  // Add salesfoce script
  const salesforce_script = document.getElementById('eci_salesforce_chat_script');

  if (!salesforce_script) {
    const script = document.createElement('script');
    script.id = 'eci_salesforce_chat_script';
    script.src = props.scriptSrc;
    script.onload = initEmbeddedMessaging;
    document.body.appendChild(script);
  } else {
    const preview_frame = document.getElementById('embeddedMessagingFilePreviewFrame');

    if (!preview_frame) {
      emit(output_events.AVAILABLE, true);
    }
  }


  // Add listeners of parent events
  const host_element = root.value;
  host_element.addEventListener(input_events.OPEN_CHAT, (e) => {
    console.info('[EciSalesforceChat] Received parent event open-chat');
    const { detail: data } = e;
    window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(data);
    const button = document.getElementById('embeddedMessagingConversationButton');
    button.click();
    emit(output_events.AVAILABLE, false);
  });

  // set interval to check if chat is opened
  setInterval(() => {
    const preview_frame = document.getElementById('embeddedMessagingFilePreviewFrame');
    emit(output_events.AVAILABLE, !preview_frame);
  }, 2000);
});

function initEmbeddedMessaging() {
  // Add the listener of the event received when the chat is ready to use
  try {
    window.addEventListener('onEmbeddedMessagingReady', () => {
      console.info('[EciSalesforceChat] Received script event the onEmbeddedMessagingReady event...');
      emit(output_events.AVAILABLE, true);
    });
    
  } catch (err) {
    console.error('[EciSalesforceChat] Error loading Embedded Messaging: ', err);
  }

  // Needed?
  // try {
  //   window.addEventListener('onEmbeddedMessagingIdentityTokenExpired', () => {
  //     console.info('[EciSalesforceChat] Received script event the onEmbeddedMessagingIdentityTokenExpired event…');
  //   });
  // } catch (e) {
  //   console.error('[EciSalesforceChat] Error onEmbeddedMessagingIdentityTokenExpired: ', e);
  // }

  // init chat
  window.embeddedservice_bootstrap.settings.language = props.language;
  window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;

  try {
    window.embeddedservice_bootstrap.init(
      props.orgId,
      props.eswConfigDevName,
      props.siteUrl,
      { scrt2URL: props.scrtUrl }
    );
  } catch(err) {
    console.error('[EciSalesforceChat] Error loading Embedded Messaging: ', err);
  }
}
</script>

<!-- <style lang="less">
@media @l-up {
  .embedded-messaging > .embeddedMessagingFrame[class~="isMaximized"] {
    max-height: calc(100vh - 9em);
  }
};
</style> -->
