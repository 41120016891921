import { mapAction, mapGetter } from 'CommonUtils/store/state.js';
import { AppEvents } from 'CommonComposables/appEvents';
import { useI18n } from 'vue-i18n';
import CART_ACTION_TYPES from 'CommonUtils/cartActionTypes';
import UtilsCart from 'CommonUtils/formatCartObject.js';

const cart = () => {
  // Computed
  const isLoadingCart = mapGetter('cart/getLoadingStatus');
  const getTotalItems = mapGetter('cart/getTotalItems');
  const fullCartData = mapGetter('cart/getFullCartData');
  const getApiResponse = mapGetter('cart/getApiResponse');
  const isEciCardError = mapGetter('cart/isEciCardError');
  const hasCouponError = mapGetter('cart/hasCouponError');
  const isLogged = mapGetter('cart/isLogged');
  const getUser = mapGetter('cart/getUser');
  const getGuestEmail = mapGetter('cart/getGuestEmail');
  const isApp = mapGetter('page/isApp');
  const internals = mapGetter('page/getInternals');
  // Actions
  const getCart = mapAction('cart', 'getCart');
  const deleteItemByCart = mapAction('cart', 'deleteItemByCart');
  const postCart = mapAction('cart', 'postCart');
  const postCartVpr = mapAction('cart', 'postCartVpr');
  const putCart = mapAction('cart', 'putCart');
  const showExpiredCartError = mapAction('cart', 'showExpiredCartError');

  const { t } = useI18n();
  const { messageEmptyCart, messageSessionCartUpdate, messageUpdateCart, messageSendUnits, messageNeedLogin } = AppEvents();

  // Methods
  const hasExpiredCartQuery = () => {
    const expiredQueryValue = internals?.value?.req?.query?.expired;
    const hasEmptyBasket = getTotalItems.value < 1;
    const isExpiredQueryValid = expiredQueryValue === 'true';
    if (!hasEmptyBasket || !isExpiredQueryValid) return; 
    showExpiredCartError({
      status: true,
      msg: t('checkout.errors.EXPIRED_CART')
    });
  };

  const sendPutCart = (options) => {
    putCart(options);
  };

  const emptyCart = () => {
    sendPutCart({});
    if(isApp.value) messageEmptyCart();
  };

  const sendGiftTicketCart = (options) => {
    postCart(options);
  };

  const sendGetCart = async () => {
    const isModal = internals?.value?.req?.query?.modal;
    const currentURL = window?.location?.href;
    if (isModal && isApp.value && currentURL?.includes('/asistente-ia-webview/')) {
      await getCart();
      hasExpiredCartQuery();
      messageSessionCartUpdate();
      messageSendUnits(getTotalItems.value);
    }
    if(isModal) return;
    await getCart();
    hasExpiredCartQuery();
    if (!isApp.value) return;
    messageSessionCartUpdate();
    messageSendUnits(getTotalItems.value);
  };

  const sendPostCartToApp = (type) => {
    const fullResponse = getApiResponse.value;
    if (fullResponse.error) return;

    const data = fullResponse.response;
    data[type] = true;
    messageUpdateCart(data);
    messageSendUnits(getTotalItems.value);
    
  };

  const sendUpdateItemCart = async (payload, is_from_checkout, successAction = CART_ACTION_TYPES.BASKET, vprPostalCode = undefined) => {
    const isFromCheckout = Boolean(is_from_checkout);
    const cartItems = {
      ...(payload.line_items?.length && {line_items: payload.line_items}),
      ...(payload.free_gift_skus?.length && {free_gift_skus: payload.free_gift_skus}),
      ...(payload.insurance_items?.length && {insurance_items: payload.insurance_items}),
    };
    if (isApp.value) {
      if (!isLogged.value) return messageNeedLogin();
      await sendPostToCart(
        cartItems,
        isFromCheckout,
        successAction,
        vprPostalCode
      ); 
      return sendPostCartToApp('add_item');
    }
    return await sendPostToCart(
      cartItems,
      isFromCheckout,
      successAction,
      vprPostalCode
    );
  };

  const handleBuyLaterAddToCart = async (items) => {
    if (isApp.value) {
      if (!isLogged.value) return messageNeedLogin();
      await postCart(items);
      return sendPostCartToApp('add_item');
    }
    await postCart(items);

  };
  
  const sendDeleteItemCart = async (line_items, is_from_checkout) => {
    if (isApp.value) {
      if (!isLogged.value) return messageNeedLogin();
      await deleteItemByCart({ line_items: line_items, is_from_checkout: is_from_checkout });
      return sendPostCartToApp('remove_item');
    }
    await deleteItemByCart({ line_items: line_items, is_from_checkout: is_from_checkout });
  };

  const hasReachedMaxInsuranceUnits = (item) => {
    return UtilsCart.isInsurance(item) && !UtilsCart.canAddInsuranceItem(this.getFullCartData.items, item.insurable_ref);
  }

  const sendPostToCart = async (cartItems, isFromCheckout, successAction, vprPostalCode) => {
    if (vprPostalCode) {
      return await postCartVpr({
        ...cartItems,
        is_from_checkout: isFromCheckout,
        successAction,
        vprPostalCode,
      });
    }
    return await postCart({
      ...cartItems,
      is_from_checkout: isFromCheckout,
      successAction
    });
  }

  return {
    isLoadingCart,
    getTotalItems,
    fullCartData,
    getApiResponse,
    isEciCardError,
    hasCouponError,
    isLogged,
    getUser,
    getGuestEmail,
    emptyCart,
    sendGiftTicketCart,
    sendGetCart,
    sendPostCartToApp,
    sendUpdateItemCart,
    handleBuyLaterAddToCart,
    sendDeleteItemCart,
    hasReachedMaxInsuranceUnits,
  }
};

export default cart;
