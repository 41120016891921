<template>
  <div :class="showOnlyModal && 'onlyModal'">
    <div
      v-show="!showOnlyModal"
      ref="buttonContainer"
      :class="wrapperClass">
      <button
        v-if="showLabel && labelPosition === 'start'"
        :class="labelClass"
        :disabled="isDisabled"
        :title="tooltip"
        @click.prevent="clickIcon">
        <p class="labelTitle">
          {{ tooltip }}
        </p>
      </button>
      <div :class="iconClass">
        <span
          v-if="totalItems && showBadge"
          class="icon-badge pointer"
          @click.stop="clickIcon">
          {{ totalItems }}
        </span>
        <i
          v-show="icon"
          class="icon-event pointer"
          :class="showSlot ? hasSecondIcon : icon"
          :title="tooltip"
          @click.stop="clickIcon"></i>
      </div>
      <span
        v-if="showLabel && labelPosition === 'end'"
        class="pointer tooltip-modal icon-span"
        :class="!showLabel ? 'labelIcon' : ''"
        @click.stop="clickIcon">
        {{ tooltip }}
      </span>
    </div>
    <div>
      <template v-if="isClientMounted">
        <Teleport
          :disabled="disablePortal"
          :to="`#Layout-modal-${modalName}`">
          <div
            v-if="showSlot"
            :style="modalParams"
            :class="'modal-icon-overlay' + typeClass"
            @click.stop="closeModal"></div>
          <transition :name="animation">
            <div
              v-show="showSlot"
              :style="modalParams"
              :class="'modal-icon' + typeClass"
              :aria-label="tooltip"
              role="dialog">
              <div>
                <slot></slot>
              </div>
              <div
                v-if="showModalClose"
                :class="'modal-icon-close' + typeClass"
                @click.stop="closeModal">
                <span class="icon cross"></span>
              </div>
            </div>
          </transition>
        </Teleport>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapAction, mapGetter, mapGetterFn } from 'CommonUtils/store/state.js';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import ICON_NAMES from 'CommonUtils/iconNames.js';

  export default {
    name: 'ActionIconSlot',
    props: {
      link: {
        type: String,
        default: null,
      },
      shouldRedirect: {
        type: Boolean,
        default: false,
      },
      modalName: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: false,
        default: () => '',
      },
      secondIcon: {
        type: String,
        required: false,
        default: () => '',
      },
      isRowLabel: {
        type: Boolean,
        required: false,
      },
      tooltip: {
        type: String,
        default: '',
      },
      showLabel: {
        type: Boolean,
        default: false,
      },
      showModalClose: {
        type: Boolean,
        default: true,
      },
      disablePortal: {
        type: Boolean,
        default: false,
      },
      showBadge: {
        type: Boolean,
        default: false,
      },
      showOnlyModal: {
        type: Boolean,
        default: false,
      },
      disabledScroll: {
        type: Boolean,
        default: true,
      },
      labelPosition: {
        type: String,
        default: 'start',
        validator(value) {
          return ['start', 'end'].includes(value);
        },
      },
      typeClass: {
        type: String,
        default: '-filter-mobile',
      },
      animation: {
        type: String,
        default: 'filter-slide-top-to-bottom',
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      modalParams: {
        type: Object,
        required: false,
        default: () => null,
      },
    },
    emits: ['iconClicked', 'closeModal'],
    setup(props, { emit }) {
      const getModalStatus = mapGetterFn('uiState/getModalStatus');
      const showModalFromState = mapAction('uiState', 'showModal');
      const hideAllModals = mapAction('uiState', 'hideAllModals');
      const totalItems = mapGetter('cart/getTotalItems');

      const { isDesktopDevice, isClientMounted } = useNavigatorDetect();

      const iconClass = computed(() => {
        return {
          icon__wrapper: props.icon,
          'icon__wrapper--disabled': props.isDisabled,
        };
      });

      const hasSecondIcon = computed(() => {
        return props.secondIcon ? props.secondIcon : props.icon;
      });

      const wrapperClass = computed(() => {
        if (props.secondIcon) return 'second__icon__wrapper';
        if (props.isRowLabel) return 'row_label';
        return null;
      });

      const showSlot = computed(() => {
        return getModalStatus(props.modalName);
      });

      const labelClass = computed(() => {
        return {
          pointer: true,
          'tooltip-modal': true,
          'wrapper_grid_options--label': true,
          labelIcon: !props.showLabel,
          'button__filter--disabled': props.showLabel && props.isDisabled,
          active: props.modalName === ICON_NAMES.CATEGORIES && showSlot.value === true,
        };
      });

      const clickIcon = () => {
        if (props.shouldRedirect) return (window.location.href = props.link);
        hideAllModals();
        if (!isDesktopDevice.value || props.disabledScroll) document.body.style.overflow = 'hidden';
        showModalFromState({ name: props.modalName, status: true });
        emit('iconClicked');
      };

      const closeModal = () => {
        if (!isDesktopDevice.value || props.disabledScroll) document.body.style.overflow = 'auto';
        showModalFromState({ name: props.modalName, status: false });
        emit('closeModal');
      };

      onUnmounted(() => {
        if (showSlot.value) {
          hideAllModals();
          if (!isDesktopDevice.value || props.disabledScroll) document.body.style.overflow = 'auto';
        }
      });

      return {
        totalItems,
        iconClass,
        hasSecondIcon,
        wrapperClass,
        showSlot,
        isClientMounted,
        clickIcon,
        closeModal,
        labelClass,
      };
    },
  };
</script>

<style lang="less" scoped>
  .filter-slide-gc-enter-active,
  .filter-slide-gc-leave-enter {
    transform: translateX(0);
    transition: all 0.2s ease-in;
  }
  .filter-slide-gc-enter-from,
  .filter-slide-gc-leave-to {
    transform: translateX(25rem);
  }

  .filter-slide-lloyds-enter-active,
  .filter-slide-lloyds-leave-enter {
    transition: all 0.3s ease-in;
  }

  .filter-slide-top-to-bottom-enter-from,
  .filter-slide-top-to-bottom-leave-to {
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }

  .right-slide-enter-from,
  .right-slide-leave-to {
    transform: translateX(100%);
  }

  .right-slide-enter-active,
  .right-slide-leave-active {
    transition: transform 0.3s;
  }

  .left-slide-enter-from,
  .left-slide-leave-to {
    transform: translateX(-100%);
  }

  .left-slide-enter-active,
  .left-slide-leave-active {
    transition: transform 0.3s;
  }
  .labelTitle {
    font-size: 13px;
  }
</style>
