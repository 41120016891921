<template>
  <section class="copyright">
    <div class="copyright__wrapper">
      <div class="copyright__copy">
        <svg class="copyright__icon" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20.6 11.6" xml:space="preserve">
          <polygon fill="#008C2E" points="0,0 0,11.6 20.6,5.8"></polygon>
        </svg>
        <span class="copyright__icon-text" v-html="$t('layouts.footer.copy_1940', { year: date.getFullYear() })"></span>
      </div>
      <ul class="copyright__links">
        <li><a class="copyright__link" :href="links?.condiciones_uso">{{ $t("layouts.footer.condiciones_uso") }}</a></li>
        <li><a class="copyright__link" :href="links?.politica_privacidad">{{ $t("layouts.footer.politica_privacidad") }}</a></li>
        <li><a class="copyright__link" :href="links?.politica_cookies">{{ $t("layouts.footer.politica_cookies") }}</a></li>
      </ul>
    </div>
  </section>
</template>

<script> export default { name: 'Copyright' }; </script>

<script setup>
  import { mapGetters } from "CommonUtils/store/state"
  const { links } = mapGetters('page', { links: 'getFooterCopyright' });
  const date = new Date();
</script>